import { useState } from 'react'

import { theme } from '@hhgtech/hhg-components'
import { StyledButton } from 'components/atoms/button'
import { Icon } from 'components/atoms/icon'
import { Text } from 'components/atoms/text'
import { StyledIconContainer } from 'components/molecules/iconContainer'
import { DropdownTopic } from 'interfaces/types'
import Link from 'next/link'
import router from 'next/router'
import { useIntl } from 'react-intl'

import {
  StyledTopicsDropdownContainer,
  StyledExpandedCard,
  StyledTitle,
  StyledContentWrapper,
} from './index.styled'

type TopicDropdownProps = {
  isExpand: boolean
  onChange: (newExpandedState: boolean) => void
  topic: DropdownTopic
}

type Props = {
  topics: DropdownTopic[]
}

const TopicDropdown = ({ isExpand, onChange, topic }: TopicDropdownProps) => {
  const { formatMessage: f } = useIntl()

  return (
    <StyledExpandedCard
      className="topic-card"
      style={{
        backgroundColor: isExpand ? theme.colors.white : topic.color,
        boxShadow: isExpand ? '0px 0px 24px rgba(0, 0, 0, 0.08)' : 'none',
      }}
      title={
        <StyledTitle>
          <StyledIconContainer
            className="img-container"
            iconSize={32}
            size={48}
            backgroundColor={isExpand ? topic.color : theme.colors.white}
            borderRadius="16px"
          >
            <img
              loading="lazy"
              src={topic.icon || '/icons/category_family.svg'}
              alt=""
            />
          </StyledIconContainer>
          <Text
            type="subtitle-2"
            className={isExpand ? 'expanded-title' : undefined}
          >
            {topic.title}
          </Text>
          <Icon
            className="right-chevron-icon"
            iconSrc="/icons/chevron.svg"
            color={isExpand ? theme.mbColors.dark : theme.mbColors.pink}
            size={18}
            style={{
              transform: `rotateZ(${isExpand ? '-90deg' : '90deg'})`,
            }}
          />
        </StyledTitle>
      }
      isExpand={isExpand}
      onChange={onChange}
      content={
        <StyledContentWrapper>
          <Text type="bodytext-2" className="description">
            {topic.description}
          </Text>
          {topic.health_tool && (
            <StyledButton
              className="healthtool-button"
              size="lg"
              onClick={() => router.push(topic?.health_tool?.permalink || '')}
              color="primary"
            >
              <img loading="lazy" src={topic.health_tool.icon} />
              <p>{topic.health_tool?.title}</p>
            </StyledButton>
          )}
          <Link href={topic.article_url}>
            <div className="read-article">
              <Text type="subtitle-2">
                {f({ id: 'together.topicDropdown.readFull' })}
              </Text>
              <Icon
                iconSrc="/icons/arrow-right_line.svg"
                color={theme.mbColors.pink}
                size={24}
              />
            </div>
          </Link>
        </StyledContentWrapper>
      }
    />
  )
}

const TopicsDropdown = ({ topics }: Props) => {
  const [expandedIndex, setExpandedIndex] = useState<number>(-1)

  return (
    <StyledTopicsDropdownContainer>
      {topics.map((topic, i) => (
        <TopicDropdown
          key={i}
          isExpand={expandedIndex === i}
          onChange={(newExpandedState: boolean) =>
            setExpandedIndex(newExpandedState ? i : -1)
          }
          topic={topic}
        />
      ))}
    </StyledTopicsDropdownContainer>
  )
}

export { TopicsDropdown }
