import { CSSProperties, useContext, useEffect } from 'react'

import { Heading } from '@hhgtech/hhg-components'
import { lazyLoadInstance } from 'utils/lazyLoad'

import { ImagePreviewPopupContext } from './imagePreviewPopup'
import { StyledImagePreview } from './index.styled'

export type Props = {
  images?: string[]
  authorName?: string
  className?: string
  style?: CSSProperties
}

const ImagePreview = ({ className, images = [], style, authorName }: Props) => {
  const moreImageCount = `+ ${(images.length || 0) - 4}`
  const { openImagePreviewPopup, setPreviewImage } =
    useContext(ImagePreviewPopupContext) || {}
  useEffect(() => {
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }
  }, [images.join(',')])
  return (
    <>
      <StyledImagePreview
        className={className}
        style={style}
        data-image-count={images.length > 5 ? 5 : images.length || 0}
      >
        {images?.slice(0, 5).map((image, index) => (
          <div
            className={`img-wrapper`}
            key={index}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (index === 4 && images.length > 5)
                openImagePreviewPopup({
                  images,
                  authorName,
                })
              else setPreviewImage(image)
            }}
          >
            <img loading="lazy" className="lazy img-preview" data-src={image} />
            {index === 4 && images.length > 5 && (
              <div className="img-more-overlay">
                <Heading tag="h1" color="white">
                  {moreImageCount}
                </Heading>
              </div>
            )}
          </div>
        ))}
      </StyledImagePreview>
    </>
  )
}

export { ImagePreview }
