import React from 'react'

import { theme } from '@hhgtech/hhg-components'
import { Container } from 'components/atoms'
import { StyledDivider } from 'components/atoms/divider'
import { ArticleCardWrapper } from 'components/molecules/articleCardWrapper'
import { DueDateCalculator } from 'components/templates/dueDateCalculator'
import { domainLocales } from 'domainLocales'
import { Article, SpotlightSummary } from 'interfaces/types'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton'
import { genPropTrackV2, TRACK_GA } from 'utils/tracking'

import { DEFAULT_IMG } from '../../../interfaces/constants'
import { DueDateSavingCard } from '../dueDateSavingCard'
import { RecommendSpotlight } from '../homepageRecommendSpotlight'
import {
  StyledArticlesContainer,
  StyledArticleContainer,
  StyledSmallArticleContainer,
  StyledArticlesDesktopContainer,
  StyledHeroContainer,
  StyledSubColumnContainer,
  StyledSecondaryContainer,
  StyledMobileDueDateContainer,
  StyledSkeletonContainer,
  StyledSkeletonArticle,
  StyledMobileArticlesBlock,
} from './index.styled'

const TrendingSkeleton = (props: { isMobile?: boolean }) => (
  <StyledSkeletonContainer>
    <StyledSkeletonArticle>
      <Skeleton height={192} />
      <Skeleton height={20} width="60%" />
      <Skeleton height={30} width="100%" />
      <Skeleton height={60} width="50%" />
      <Skeleton height={20} width="80%" />
    </StyledSkeletonArticle>

    <div
      className="sub-articles-group"
      is-mobile={props.isMobile ? 'true' : 'false'}
    >
      {Array(2)
        .fill(true)
        .map((_, i) => (
          <React.Fragment key={`sub-ske-${i}`}>
            {props.isMobile ? (
              <StyledSkeletonArticle className="mobile-skeleton-article">
                <div>
                  <Skeleton height={96} width={96} />
                </div>
                <div>
                  <Skeleton height={20} width="2" />
                  <Skeleton height={45} width="100%" />
                  <Skeleton height={25} width="60%" />
                </div>
              </StyledSkeletonArticle>
            ) : (
              <StyledSkeletonArticle>
                <Skeleton height={192} />
                <Skeleton height={20} width="60%" />
                <Skeleton height={20} width="100%" />
                <Skeleton height={60} width="80%" />
              </StyledSkeletonArticle>
            )}
          </React.Fragment>
        ))}
    </div>
  </StyledSkeletonContainer>
)

export type Props = {
  isTrendingLoading: boolean
  onTrendingTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
  onTrendingTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => void
  onTrendingTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void
  onArticleClick?: (l: string) => void
  trendingPosts?: Article[]
  spotlights: SpotlightSummary[]
  isMobile?: boolean
}

const HomepageTrendingCard = ({
  trendingPosts = [],
  spotlights,
  isTrendingLoading,
  onTrendingTouchEnd,
  onTrendingTouchMove,
  onTrendingTouchStart,
}: Props) => {
  const { locale } = useRouter()
  const sL = locale || 'vi-VN' // standardized locale

  return (
    <>
      <StyledArticlesContainer
        onTouchStart={onTrendingTouchStart}
        onTouchMove={onTrendingTouchMove}
        onTouchEnd={onTrendingTouchEnd}
        className="mbUp"
      >
        {isTrendingLoading ? (
          <TrendingSkeleton isMobile />
        ) : (
          <StyledMobileArticlesBlock>
            {trendingPosts.slice(0, 1).map((article: Article) => (
              <StyledArticleContainer
                id="mobile-first-article-block"
                key={article.id}
              >
                <Container>
                  <ArticleCardWrapper
                    article={article}
                    dataEventActionForTitle="Click 1"
                    dataEventActionForCategory="Click 1"
                    dataEventActionForBanner="Click 1"
                    dataEventActionForContent="Click 1"
                    dataEventCategoryForTitle={TRACK_GA.ARTICLE.category}
                    dataEventCategoryForCategory={TRACK_GA.ARTICLE.category}
                    dataEventCategoryForBanner={TRACK_GA.ARTICLE.category}
                    dataEventCategoryForContent={TRACK_GA.ARTICLE.category}
                    dataEventLabelForTitle={`https://${domainLocales[sL]}${article.permalink}`}
                    dataEventLabelForCategory={`https://${domainLocales[sL]}${article.category.permalink}`}
                    dataEventLabelForBanner={`https://${domainLocales[sL]}${article.permalink}`}
                    dataEventLabelForContent={`https://${domainLocales[sL]}${article.permalink}`}
                    type="author"
                    size="lg"
                    isThumbnailVideoUsed={!!article.videoInfo}
                    banner={
                      <img
                        loading="lazy"
                        src={
                          article.featureImageMobile ||
                          article.featureImage ||
                          DEFAULT_IMG
                        }
                        alt={article.postTitle}
                      />
                    }
                    avatarImgRenderer={(avatar, name) => (
                      <img
                        loading="lazy"
                        src={avatar}
                        style={{
                          width: 24,
                          height: 24,
                          objectFit: 'cover',
                        }}
                        alt={name}
                      />
                    )}
                    categoryLink={article.category.permalink}
                    articleLink={article.permalink}
                    direction="vertical"
                    categoryName={article.category?.displayName}
                    title={article.postTitle}
                    text={article.excerpt}
                    styleLabel={{
                      color: article.category.textColor,
                    }}
                    uploadTime={article.modifiedTime}
                  />
                </Container>
                <div style={{ marginTop: 8, padding: '0 16px' }}>
                  <StyledDivider type="solid" />
                </div>
              </StyledArticleContainer>
            ))}

            {trendingPosts.slice(1, 3).map((article: Article, id) => (
              <StyledSmallArticleContainer key={article.id}>
                <Container>
                  <ArticleCardWrapper
                    article={article}
                    // dataEventCategoryForTitle="Top Article Title"
                    // dataEventActionForTitle={`Click ${id + 4}`}
                    // dataEventLabelForTitle={`https://${domainLocales[sL]}${article.permalink}`}
                    // dataEventCategoryForBanner="Top Article Banner"
                    // dataEventActionForBanner={`Click ${id + 4}`}
                    // dataEventLabelForBanner={`https://${domainLocales[sL]}${article.permalink}`}
                    // dataEventActionForCategory="Top Category Title"
                    // dataEventCategoryForCategory={`Click ${id + 4}`}
                    // dataEventLabelForCategory={`https://${domainLocales[sL]}${article.category.permalink}`}

                    dataEventCategoryForCategory={TRACK_GA.ARTICLE.category}
                    dataEventCategoryForTitle={TRACK_GA.ARTICLE.category}
                    dataEventCategoryForBanner={TRACK_GA.ARTICLE.category}
                    dataEventActionForTitle={`Click ${id + 2}`}
                    dataEventLabelForTitle={`https://${domainLocales[sL]}${article.permalink}`}
                    dataEventActionForCategory={`Click ${id + 2}`}
                    dataEventLabelForCategory={`https://${domainLocales[sL]}${article.category.permalink}`}
                    dataEventActionForBanner={`Click ${id + 2}`}
                    dataEventLabelForBanner={`https://${domainLocales[sL]}${article.permalink}`}
                    dataEventCategoryForContent={TRACK_GA.ARTICLE.category}
                    dataEventActionForContent={`Click ${id + 2}`}
                    dataEventLabelForContent={`https://${domainLocales[sL]}${article.permalink}`}
                    type="author"
                    size="md"
                    banner={
                      <img
                        loading="lazy"
                        src={
                          article.featureImageMobileXs ||
                          article.featureImage ||
                          DEFAULT_IMG
                        }
                        style={{ width: '100%', height: '100%' }}
                        alt={article.postTitle}
                      />
                    }
                    styleLabel={{
                      color: article.category.textColor,
                    }}
                    categoryLink={article.category.permalink}
                    articleLink={article.permalink}
                    categoryName={article.category?.displayName}
                    title={article.postTitle}
                    text={article.excerpt}
                    avatarImgRenderer={(avatar, name) => (
                      <img
                        loading="lazy"
                        src={avatar}
                        style={{ width: 24, height: 24, objectFit: 'cover' }}
                        alt={name}
                      />
                    )}
                    uploadTime={article.modifiedTime}
                    showDescription={false}
                  />
                  {id !== 1 && (
                    <div>
                      <StyledDivider type="solid" />
                    </div>
                  )}
                </Container>
              </StyledSmallArticleContainer>
            ))}
          </StyledMobileArticlesBlock>
        )}

        <RecommendSpotlight spotlights={spotlights} isMobile />

        <StyledMobileDueDateContainer>
          <div className="due-date-calc-content">
            <DueDateCalculator
              isEmbed
              btnProp={genPropTrackV2(
                TRACK_GA.DUE_DATE,
                `https://${domainLocales[sL]}`,
              )}
            />
            <DueDateSavingCard
              btnProp={genPropTrackV2(
                TRACK_GA.DUE_DATE,
                `https://${domainLocales[sL]}`,
              )}
            />
          </div>
        </StyledMobileDueDateContainer>
      </StyledArticlesContainer>

      <Container
        style={{
          backgroundColor: theme.colors.white,
        }}
        className="mbDown"
      >
        <StyledArticlesDesktopContainer>
          <StyledHeroContainer>
            {isTrendingLoading ? (
              <TrendingSkeleton />
            ) : (
              <>
                {trendingPosts.slice(0, 1).map((article: Article) => (
                  <StyledArticleContainer
                    key={article.id}
                    id="desktop-first-article-block"
                  >
                    <ArticleCardWrapper
                      article={article}
                      // dataEventCategoryForTitle="Top Article Title"
                      // dataEventCategoryForBanner="Top Article Banner"
                      // dataEventCategoryForCategory="Top Category Title"
                      dataEventActionForTitle="Click 1"
                      dataEventActionForCategory="Click 1"
                      dataEventActionForBanner="Click 1"
                      dataEventActionForContent={`Click 1`}
                      dataEventCategoryForTitle={TRACK_GA.ARTICLE.category}
                      dataEventCategoryForCategory={TRACK_GA.ARTICLE.category}
                      dataEventCategoryForBanner={TRACK_GA.ARTICLE.category}
                      dataEventCategoryForContent={TRACK_GA.ARTICLE.category}
                      dataEventLabelForTitle={`https://${domainLocales[sL]}${article.permalink}`}
                      dataEventLabelForCategory={`https://${domainLocales[sL]}${article.category.permalink}`}
                      dataEventLabelForBanner={`https://${domainLocales[sL]}${article.permalink}`}
                      dataEventLabelForContent={`https://${domainLocales[sL]}${article.permalink}`}
                      type="author"
                      direction="vertical"
                      size="lg"
                      isThumbnailVideoUsed={!!article.videoInfo}
                      banner={
                        <img
                          loading="lazy"
                          src={
                            article.featureImageMobile ||
                            article.featureImage ||
                            DEFAULT_IMG
                          }
                          alt={article.postTitle}
                          key={article.id}
                          style={{ width: '100%', height: '100%' }}
                        />
                      }
                      styleLabel={{
                        color:
                          article.category.textColor || theme.mbColors.cobalt,
                      }}
                      categoryLink={article.category.permalink}
                      articleLink={article.permalink}
                      categoryName={article.category?.displayName}
                      title={article.postTitle}
                      text={article.excerpt}
                      avatarImgRenderer={(avatar, name) => (
                        <img
                          loading="lazy"
                          src={avatar}
                          style={{
                            width: 32,
                            height: 32,
                            objectFit: 'cover',
                          }}
                          alt={name}
                        />
                      )}
                      uploadTime={article.modifiedTime}
                    />
                  </StyledArticleContainer>
                ))}

                <div style={{ paddingRight: 12 }}>
                  <StyledDivider type="solid" />
                </div>

                <StyledSecondaryContainer>
                  {trendingPosts
                    .slice(1, 3)
                    .map((article: Article, index: number) => (
                      <StyledArticleContainer key={article.id}>
                        <ArticleCardWrapper
                          article={article}
                          // dataEventCategoryForTitle="Top Article Title"
                          // dataEventCategoryForCategory="Top Category Title"
                          // dataEventCategoryForBanner="Top Article Banner"
                          // dataEventActionForTitle="Click 2"
                          // dataEventActionForCategory="Click 2"
                          // dataEventActionForBanner="Click 2"
                          dataEventCategoryForCategory={
                            TRACK_GA.ARTICLE.category
                          }
                          dataEventCategoryForTitle={TRACK_GA.ARTICLE.category}
                          dataEventCategoryForBanner={TRACK_GA.ARTICLE.category}
                          dataEventCategoryForContent={
                            TRACK_GA.ARTICLE.category
                          }
                          dataEventActionForTitle={`Click ${index + 2}`}
                          dataEventActionForContent={`Click ${index + 2}`}
                          dataEventLabelForTitle={`https://${domainLocales[sL]}${article.permalink}`}
                          dataEventLabelForContent={`https://${domainLocales[sL]}${article.permalink}`}
                          dataEventActionForCategory={`Click ${index + 2}`}
                          dataEventLabelForCategory={`https://${domainLocales[sL]}${article.category.permalink}`}
                          dataEventActionForBanner={`Click ${index + 2}`}
                          dataEventLabelForBanner={`https://${domainLocales[sL]}${article.permalink}`}
                          type="author"
                          direction="vertical"
                          size="md"
                          banner={
                            <img
                              loading="lazy"
                              src={
                                article.featureImageMobile ||
                                article.featureImage ||
                                DEFAULT_IMG
                              }
                              alt={article.postTitle}
                              key={article.id}
                              style={{ width: '100%', height: '100%' }}
                            />
                          }
                          styleLabel={{
                            color:
                              article.category.textColor ||
                              theme.mbColors.cobalt,
                          }}
                          categoryLink={article.category.permalink}
                          articleLink={article.permalink}
                          categoryName={article.category?.displayName}
                          title={article.postTitle}
                          text={article.excerpt}
                          avatarImgRenderer={(avatar, name) => (
                            <img
                              loading="lazy"
                              src={avatar}
                              style={{
                                width: 24,
                                height: 24,
                                objectFit: 'cover',
                              }}
                              alt={name}
                            />
                          )}
                          uploadTime={article.modifiedTime}
                          showDescription={false}
                        />
                      </StyledArticleContainer>
                    ))}
                </StyledSecondaryContainer>
              </>
            )}
          </StyledHeroContainer>

          <StyledSubColumnContainer>
            <DueDateCalculator
              isEmbed
              btnProp={genPropTrackV2(
                TRACK_GA.DUE_DATE,
                `https://${domainLocales[sL]}`,
              )}
            />
            <DueDateSavingCard
              btnProp={genPropTrackV2(
                TRACK_GA.DUE_DATE,
                `https://${domainLocales[sL]}`,
              )}
            />
          </StyledSubColumnContainer>
        </StyledArticlesDesktopContainer>
      </Container>
    </>
  )
}

export { HomepageTrendingCard }
