import { useContext, useState } from 'react'

import { theme } from '@hhgtech/hhg-components'
import { PATHS } from 'api/paths'
import {
  callApiWithAuth,
  getTogetherPath,
  redirectToSSOWithReturnUrl,
} from 'api/utils'
import { TagTopic } from 'components/atoms/tagTopic'
import { Text } from 'components/atoms/text'
import { UserAvatar } from 'components/atoms/userAvatar'
import { StyledIconContainer } from 'components/molecules/iconContainer'
import { HomePageCommunityPostApi } from 'interfaces/types'
import { useIntl } from 'react-intl'
import { AppContext } from 'state/appContext'

import { CardPostContent } from '../CommunityCard/cardPostContent'
import { ImagePreview } from './imagePreview'
import { StyledCommunityPostCardContainer } from './index.styled'

type Props = HomePageCommunityPostApi & {
  isMobile: boolean
  className?: string
  disableShortenContent?: boolean
}

const CommunityPost = ({
  author_avatar,
  author_full_name,
  title,
  community_name,
  count_likes: countLikesProp,
  count_comments,
  count_shares = 0,
  created_time,
  images,
  liked: likedProp,
  post_id,
  slug: post_slug,
  community_slug,
  description,
  mention,
  disableShortenContent = false,

  isMobile,
  className,
  created_moderator_id,
  moderator_avatar,
  moderator_id,
  moderator_name,
  author_name,
  topics,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const {
    state: { isLoggedIn },
  } = useContext(AppContext)
  const [liked, setLiked] = useState<boolean>(likedProp)
  const [countLikes, setCountLikes] = useState(countLikesProp)

  const redirectToCommunity = () => {
    window.location.href = window.location.origin.concat(
      '/community/',
      community_slug,
      '/',
      post_slug,
    )
  }

  const onLikeClick = async () => {
    if (isLoggedIn) {
      if (!liked) {
        try {
          await callApiWithAuth(
            getTogetherPath(PATHS.GET_TOGETHER_POST_LIKE, { postId: post_id }),
            'GET',
          )
          setLiked(true)
          setCountLikes((c) => c + 1)
        } catch (e) {
          console.log(e)
        } finally {
        }
      } else {
        try {
          await callApiWithAuth(
            getTogetherPath(PATHS.GET_TOGETHER_POST_LIKE, { postId: post_id }),
            'GET',
          )
          setLiked(false)
          setCountLikes((c) => c - 1)
        } catch (e) {
          console.log(e)
        } finally {
        }
      }
    } else {
      const destination = `${
        typeof window !== 'undefined' ? window.location.origin : ''
      }/community/${community_slug}/${post_slug}`
      redirectToSSOWithReturnUrl(destination)
    }
  }

  const isModerator = created_moderator_id || moderator_id
  const name = (isModerator ? moderator_name : '') || author_name || ''
  const avatar = (isModerator ? moderator_avatar : '') || author_avatar || ''

  return (
    <StyledCommunityPostCardContainer
      className={className}
      onClick={redirectToCommunity}
    >
      <div className="header">
        <StyledIconContainer size={isMobile ? 32 : 40}>
          <UserAvatar avatar={avatar} username={name} />
        </StyledIconContainer>
        <div className="header__text">
          <div>
            <Text type="bodytext-2" className="bold">
              {name}
            </Text>
            <Text
              type={isMobile ? 'caption-2' : 'bodytext-3'}
              color={theme.mbColors.midGray}
              className="time"
            >
              {created_time}
            </Text>
          </div>
          <Text
            type="bodytext-3"
            color={theme.mbColors.cobalt}
            className="bold"
          >
            {community_name}
          </Text>
        </div>
        <img
          loading="lazy"
          className="more-icon"
          src="/icons/more_solid.svg"
          alt="more"
        />
      </div>

      <Text type="subtitle-1" className="post-title">
        {title}
      </Text>

      <CardPostContent
        description={description}
        mentions={mention}
        disableShortenContent={disableShortenContent}
      />

      <div key="tag-topic">
        {topics && topics.length > 0 ? (
          <TagTopic
            topics={topics.map((t) => ({
              id: t.topic_id,
              name: t.topic_name,
              slug: `${community_slug}/${t.topic_slug}`,
            }))}
            className="post-topics"
          />
        ) : null}
      </div>

      {images && images.length > 0 ? (
        <div className="image-preview-wrapper">
          <ImagePreview images={images} authorName={author_full_name} />
        </div>
      ) : null}

      <div className="count">
        <div onClick={onLikeClick}>
          {liked ? (
            <img loading="lazy" src="/icons/heart_solid.svg" alt="" />
          ) : (
            <img loading="lazy" src="/icons/like_line.svg" />
          )}
          <Text type="bodytext-3">
            {countLikes} {f({ id: 'community.like' })}
          </Text>
        </div>
        <div>
          <img loading="lazy" src="/icons/comment_line.svg" />
          <Text type="bodytext-3">
            {count_comments} {f({ id: 'community.comment' })}
          </Text>
        </div>
        <div>
          <img loading="lazy" src="/icons/share_line.svg" />
          <Text type="bodytext-3">
            {count_shares} {f({ id: 'community.share' })}
          </Text>
        </div>
      </div>
    </StyledCommunityPostCardContainer>
  )
}

export { CommunityPost }
