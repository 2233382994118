import {
  CSSProperties,
  createContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'

import { Text } from '@hhgtech/hhg-components'
import { useIntl } from 'react-intl'

import { StyledImagePreviewPopup } from './index.styled'

export type Props = {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

export type Context = {
  openImagePreviewPopup: (data: ContextData) => void
  setPreviewImage: (s: string) => void
}
export type ContextData = {
  images: string[]
  authorName?: string
}

export const ImagePreviewPopupContext = createContext({} as Context)

const ImagePreviewPopupProviderWrapper = ({
  className,
  style,
  children,
}: Props) => {
  const [data, setData] = useState<ContextData | null>(null)
  const [previewImage, setPreviewImage] = useState('')
  const { formatMessage: f } = useIntl()
  const showPopup = !!(data || previewImage)
  useEffect(() => {
    document.body.classList.toggle('noscroll', showPopup)
  }, [showPopup])
  return (
    <ImagePreviewPopupContext.Provider
      value={{
        openImagePreviewPopup: (_data) => {
          setData(_data)
        },
        setPreviewImage: (src) => {
          setPreviewImage(src)
        },
      }}
    >
      <StyledImagePreviewPopup
        className={className}
        style={style}
        data-open={showPopup}
      >
        {!!data && (
          <>
            <div className="top-bar">
              <img
                loading="lazy"
                className="icon-arrow-left"
                src="./svg/icon-arrow-left.svg"
                onClick={() => setData(null)}
              />
              <Text
                size="p2"
                type="bold"
                style={{
                  textAlign: 'center',
                }}
              >
                {f(
                  {
                    id: 'previewPhotos.title',
                  },
                  {
                    name: data?.authorName || '_',
                  },
                )}
              </Text>
            </div>
            <div className="container">
              {data?.images.map((image, index) => (
                <img
                  loading="lazy"
                  className="img-preview"
                  key={index}
                  src={image}
                  onClick={() => setPreviewImage(image)}
                />
              ))}
            </div>
          </>
        )}
        <div className="preview-container" data-open={!!previewImage}>
          <div className="preview-top-bar">
            <img
              loading="lazy"
              src="./images/close.svg"
              className="icon-close"
              onClick={() => setPreviewImage('')}
            />
          </div>
          <img loading="lazy" className="img-preview" src={previewImage} />
        </div>
      </StyledImagePreviewPopup>
      {children}
    </ImagePreviewPopupContext.Provider>
  )
}

export { ImagePreviewPopupProviderWrapper }
