import { Z_INDEX } from 'interfaces/constants'
import styled from 'styled-components'

export const StyledFixedAdBlock = styled.div`
  position: fixed;
  z-index: ${Z_INDEX.adBlock};
  bottom: 0;
  left: 50%;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;
  background: none;
  transform: translateX(-50%);
  iframe {
    margin: 1rem 0.5rem 0;
  }
  .close-sticky-button {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    background: #bfbfbf;
    border-radius: 50%;
    color: white;

    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    transform: translate(50%, -50%);
  }
`

export const StyledAdContainer = styled.div`
  display: flex;
  justify-content: center;
  line-height: 0;
`
