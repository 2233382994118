import React, { useEffect, useState } from 'react'

import { Button, Heading, Text, theme } from '@hhgtech/hhg-components'
import { PATHS } from 'api/paths'
import { callApi, getApiPath } from 'api/utils'
import { Loading } from 'components/atoms/loading'
import { useIntl } from 'react-intl'

import {
  StyledDotSeparator,
  StyledItem,
  StyledPopup,
  StyledPopupWrapper,
  StyledProfileImage,
  StyledSeparator,
  StyledTitleContainer,
} from './index.styled'

export type Experience = {
  title: string
  organization: string
  duration: string
}
export type ExpertInfo = {
  avatar?: string
  role?: string
  display_name: string
  specialty: string
  organisation: string
  experiences: Array<Experience>
  organisation_url: string
  profile_url: string
  user_id: number
}
export type Props = {
  isOpen: boolean
  onClose: () => void
  enableAnimation?: boolean
  expertId: string
}

const PopupExpert = ({
  isOpen = true,
  onClose,
  enableAnimation = true,
  expertId,
}: Props) => {
  const { formatMessage: f } = useIntl()

  const [expertInfo, setExpertInfo] = useState<ExpertInfo | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      isOpen &&
      expertId &&
      String(expertInfo?.user_id) !== String(expertId)
    ) {
      setIsLoading(true)
      try {
        setExpertInfo(null)
        callApi(
          getApiPath(PATHS.GET_EXPERT_BY_ID, {
            id: expertId,
          }),
          'get',
        ).then((res) => {
          setExpertInfo(res?.data)
        })
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
  }, [isOpen, expertId, expertInfo?.user_id])

  return (
    <>
      <StyledPopup
        data-open={isOpen}
        data-animation={enableAnimation}
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading || !expertInfo ? (
          <Loading />
        ) : (
          <div className="popup-content">
            <div className="close-button" onClick={onClose}>
              <img
                loading="lazy"
                src={`./images/closeLight.svg`}
                style={{ width: 20, height: 20 }}
                alt="close-icon"
              />
            </div>
            <div className="main-info">
              <div className="border-banner" />
              <div className="avatar-title">
                <StyledProfileImage>
                  <div className="image-container">
                    <div style={{ position: 'relative' }}>
                      <img
                        loading="lazy"
                        src={
                          expertInfo?.avatar
                            ? expertInfo?.avatar
                            : `./images/avatar-default.png`
                        }
                        className="profile-image"
                        alt="avatar"
                      />
                      <img
                        loading="lazy"
                        className="badge-avatar"
                        src={`./images/reviewer-${expertInfo?.role}.svg`}
                        alt="badge"
                      />
                    </div>
                  </div>
                </StyledProfileImage>
                <StyledTitleContainer>
                  <Heading tag="h3" className="full-name">
                    {expertInfo?.display_name}
                  </Heading>
                  <div className="specialty">
                    <Text size="p4" color={theme.colors.gray800}>
                      {expertInfo?.specialty}
                    </Text>
                    <StyledSeparator>
                      <StyledDotSeparator />
                    </StyledSeparator>
                    <Text
                      size="p4"
                      className="organisation"
                      color={theme.colors.gray500}
                    >
                      <a
                        href={expertInfo?.organisation_url}
                        className="organisation"
                        target="_blank"
                      >
                        <span>{expertInfo?.organisation}</span>
                      </a>
                    </Text>
                  </div>
                </StyledTitleContainer>
              </div>
            </div>
            <StyledTitleContainer>
              <div className="experience">
                <Text type="bold" size="p4" color={theme.colors.gray400}>
                  {f({
                    id: 'expert.content.experiences',
                    defaultMessage: 'Experiences',
                  }).toUpperCase()}
                </Text>
                <div className="list-items">
                  {expertInfo?.experiences
                    ? expertInfo.experiences
                        .slice(0, 3)
                        .map((experience, index) => (
                          <StyledItem key={index}>
                            <img
                              loading="lazy"
                              className="bullet-point"
                              src="./svg/bullet-point.svg"
                              alt="point"
                            />
                            <div className="text-contain">
                              <Heading tag="h6" color={theme.colors.gray800}>
                                {experience?.title}
                              </Heading>
                              <Text size="p3" color={theme.colors.gray500}>
                                {experience?.organization}
                              </Text>
                            </div>
                          </StyledItem>
                        ))
                    : false}
                </div>
              </div>
            </StyledTitleContainer>
            <div className="button-see-more">
              <a href={expertInfo?.profile_url}>
                <Button size="lg" color="secondary">
                  {f({
                    id: 'home.seeMore',
                  })}
                </Button>
              </a>
            </div>
          </div>
        )}
      </StyledPopup>
      <StyledPopupWrapper
        data-open={isOpen}
        onClick={(e) => {
          e.stopPropagation()
          onClose()
        }}
      />
    </>
  )
}

export { PopupExpert }
