import styled from 'styled-components'

export const StyledTagTopic = styled.div`
  display: flex;
  width: 100%;
  cursor: grab;
  overflow-x: auto;
  .tag-link-item {
    padding: 5px 12px;
    border: 1px solid #e4e8ec;
    background: white;
    border-radius: 2rem;
    cursor: pointer;
    -webkit-user-drag: none;
    user-select: none;
    white-space: nowrap;
  }
  .tag-link-item:not(:first-child) {
    margin-left: 0.5rem;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
  &.sliding {
    cursor: grabbing;
    user-select: none;
    a {
      pointer-events: none;
    }
  }
  a {
    text-decoration: none;
  }
`
