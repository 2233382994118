import React, { CSSProperties, useEffect, useState } from 'react'

import { StyledCountdownItem, StyledWrap } from './index.styled'

type Props = {
  date: string
  className?: string
  style?: CSSProperties
}

const defaultInfo = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
}

export function Countdown({ date, className, style }: Props) {
  const [info, setInfo] = useState(defaultInfo)

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timer
    const countDownDate = new Date(date).getTime()

    // Update the count down every 1 second
    const handler = () => {
      // Get today's date and time
      const now = new Date().getTime()

      // Find the distance between now and the count down date
      const distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(intervalId)
        setInfo(defaultInfo)
      } else {
        setInfo({
          days: days.toString().padStart(2, '0'),
          hours: hours.toString().padStart(2, '0'),
          minutes: minutes.toString().padStart(2, '0'),
          seconds: seconds.toString().padStart(2, '0'),
        })
      }
    }
    handler()
    intervalId = setInterval(handler, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [date])

  return (
    <StyledWrap className={className} style={style}>
      <div className="countdown">
        <StyledCountdownItem>
          <span className="countdown--number">{info.days}</span>
          <span className="countdown--label">Ngày</span>
        </StyledCountdownItem>
        <StyledCountdownItem>
          <span className="countdown--number">{info.hours}</span>
          <span className="countdown--label">Giờ</span>
        </StyledCountdownItem>
        <StyledCountdownItem>
          <span className="countdown--number">{info.minutes}</span>
          <span className="countdown--label">Phút</span>
        </StyledCountdownItem>
        <StyledCountdownItem>
          <span className="countdown--number">{info.seconds}</span>
          <span className="countdown--label">Giây</span>
        </StyledCountdownItem>
      </div>
    </StyledWrap>
  )
}
