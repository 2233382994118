import { useState } from 'react'

import {
  getNavMenuContent,
  NavMenuContentType,
} from '@hhgtech/hhg-components/navigation'
import { FooterInfoMarryBaby } from '@hhgtech/hhg-components/types'
import {
  cleanUpUndefined,
  formatCategory,
  formatHealthTool,
  formatHealthToolTypeCategory,
  formatSiteWideBanner,
  formatSpotlightsList,
} from 'api/dataTransform'
import { PATHS } from 'api/paths'
import { callApi, checkMobile, getApiPath } from 'api/utils'
import { PageBody } from 'components/pages/homepage/pageBody'
import { NavigatorLayout } from 'components/templates'
import { useCheckCloseBanner } from 'hooks/useCheckCloseBanner'
import { LOCALE_SPECS } from 'interfaces/constants'
import {
  ArticleApi,
  Category,
  CategoryHealthToolApi,
  DropdownTopic,
  ExpertHeading,
  ExpertUserSummaryApi,
  HealthTool,
  HealthToolByCategory,
  MedicalReviewApi,
  SiteWideBanner,
  SpotlightSummary,
  WeekOfPregnancyApi,
} from 'interfaces/types'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { addFrisoCategory } from 'utils/common'

type FeaturedTopicApi = {
  posts: ArticleApi[]
  category: {
    term_id: number
    name: string
    permalink: string
    text_color: string
  }
}

type Props = {
  isMobile?: boolean
  trendingArticles: ArticleApi[]
  categories: Category[]
  healthTools: HealthTool[]
  healthToolsByCategories: HealthToolByCategory[]
  healthyLivingArticles: FeaturedTopicApi
  pregnancyArticles: FeaturedTopicApi
  drugArticles: FeaturedTopicApi
  expertsArticles: ExpertUserSummaryApi[]
  medicalReviewed: MedicalReviewApi[]
  spotlights: SpotlightSummary[]
  expertHeading: ExpertHeading
  sitewideBanner?: SiteWideBanner | null
  homepageTopics: DropdownTopic[]
  pregnancyWeeks: WeekOfPregnancyApi[]
  schemaData: string
  footerMenu: FooterInfoMarryBaby
  dataNavMenuContent?: NavMenuContentType | null
}

const Home = ({
  isMobile: _isMobile,
  trendingArticles,
  categories,
  healthTools,
  healthyLivingArticles,
  drugArticles,
  pregnancyArticles,
  expertsArticles,
  medicalReviewed,
  spotlights,
  expertHeading,
  sitewideBanner,
  homepageTopics,
  pregnancyWeeks,
  schemaData = '',
  footerMenu,
  dataNavMenuContent,
}: Props) => {
  const [isTopBarAppears, setIsTopBarAppears] = useState(false)
  let schemaStr = ''

  if (typeof window !== 'undefined' && schemaData) {
    if (schemaData.startsWith('<script')) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(schemaData || '', 'text/html')
      const scriptElm = doc.querySelector('script')
      if (scriptElm?.innerText) {
        schemaStr = scriptElm?.innerText
      }
    } else {
      schemaStr = schemaData
    }
  }

  const onTopBarAppears = (b: boolean) => {
    setIsTopBarAppears(b)
  }
  const { locale = 'vi-VN' } = useRouter()
  const { formatMessage: f } = useIntl()

  const closeSiteWideCookie = useCheckCloseBanner(
    'discover-disable-sitewide-home',
  )

  return (
    <>
      {/* <div
        dangerouslySetInnerHTML={{
          __html: `<script>
          document.getElementById('slider')?.addEventListener('swipe', function (event, slick, direction) {
              dataLayer.push({"event": "sliderSwipe", "swipeDirection": direction});
          });
      </script>      
    `,
        }}
      /> */}
      {/* <style>{`svg, img, p, button, a { filter: grayscale(1); }`}</style> */}
      <NavigatorLayout
        title={f({ id: 'home.seoTitle' })}
        description={f({ id: 'home.seoDescription' })}
        image={LOCALE_SPECS[locale].HOMEPAGE_IMAGE}
        onTopBarAppears={onTopBarAppears}
        isSearchBarDisplay
        SEOData={{
          schemaData: schemaStr || f({ id: 'home.seoSchema' }),
          isIndexable: true,
          hasNoAmp: true,
        }}
        isMobile={_isMobile}
        isHomePage={true}
        sitewideBanner={closeSiteWideCookie ? null : sitewideBanner}
        footerMenu={footerMenu}
        dataNavMenuContent={dataNavMenuContent}
      >
        <PageBody
          isMobileSsr={!!_isMobile}
          trendingArticles={trendingArticles}
          categories={categories}
          healthTools={healthTools}
          healthyLivingArticles={healthyLivingArticles}
          drugArticles={drugArticles}
          pregnancyArticles={pregnancyArticles}
          expertsArticles={expertsArticles}
          isTopBarAppears={isTopBarAppears}
          medicalReviewed={medicalReviewed}
          spotlights={spotlights}
          expertHeading={expertHeading}
          homepageTopics={homepageTopics}
          pregnancyWeeks={pregnancyWeeks}
        />
      </NavigatorLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context
  const userAgent = `${req.headers['user-agent']}`

  const isMobile = checkMobile(userAgent)

  const results = await Promise.all([
    callApi(
      getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
        type: '',
      }),
      'GET',
    ),
    callApi(getApiPath(PATHS.GET_ALL_CATEGORIES), 'GET'),
    callApi(getApiPath(PATHS.GET_ALL_HEALTH_TOOLS), 'GET'),
    callApi(getApiPath(PATHS.GET_HEALTH_TOOLS_BY_CATEGORY), 'GET'),
    callApi(
      getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
        type: 'healthy-living',
      }),
      'GET',
    ),
    callApi(
      getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
        type: 'drug',
      }),
      'GET',
    ),
    callApi(
      getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
        type: 'family-pregnancy',
      }),
      'GET',
    ),
    callApi(
      getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
        type: 'experts',
      }),
      'GET',
    ),
    callApi(getApiPath(PATHS.GET_HOMEPAGE_MEDICAL_REVIEWED), 'GET'),

    callApi(
      getApiPath(PATHS.GET_ALL_SPOTLIGHT, {
        perPage: 10,
        orderBy: 'start_date',
      }),
      'GET',
    ),
    callApi(getApiPath(PATHS.GET_ALL_EXPERTS_HEADING), 'GET'),
    callApi(getApiPath(PATHS.GET_HOMEPAGE_SITEWIDE_BANNER), 'GET'),
    callApi(getApiPath(PATHS.GET_DROPDOWN_TOPICS), 'GET'),
    callApi(getApiPath(PATHS.GET_DUE_DATE_ALL_WEEKS), 'GET'),
    callApi(getApiPath(PATHS.GET_DYNAMIC_FOOTER), 'GET'),
    getNavMenuContent('vi-VN_MB'),
    callApi(getApiPath(PATHS.GET_HOMEPAGE_SCHEMA), 'GET'),
  ])

  const [
    trendingArticles,
    categoriesRes,
    healthTools,
    healthToolsDataByCategories,
    healthyLivingArticles,
    drugArticles,
    pregnancyArticles,
    expertsArticles,
    medicalReviewed,
    spotlightsRes,
    expertHeadingRes,
    sitewideBannerRes,
    homepageTopics,
    pregnancyProgress,
    footer,
    navMenuContent,
    schemaRes,
  ] = results

  const categories: Category[] = categoriesRes?.data?.map(formatCategory) ?? []

  return {
    props: {
      isMobileSsr: isMobile,
      trendingArticles: trendingArticles?.data ?? [],
      categories: addFrisoCategory(categories),
      healthTools:
        healthTools?.data && healthTools?.data.length > 0
          ? healthTools?.data?.map(formatHealthTool)
          : [],
      healthToolsByCategories:
        healthToolsDataByCategories?.data &&
        healthToolsDataByCategories?.data.length > 0
          ? healthToolsDataByCategories?.data?.map(
              (category: CategoryHealthToolApi) =>
                formatHealthToolTypeCategory(category),
            )
          : [],
      healthyLivingArticles: healthyLivingArticles?.data ?? [],
      drugArticles: drugArticles?.data ?? [],
      pregnancyArticles: pregnancyArticles?.data ?? [],
      expertsArticles: expertsArticles?.data ?? [],
      medicalReviewed: medicalReviewed?.data ?? [],
      spotlights: formatSpotlightsList(spotlightsRes?.data?.articles) || [],
      expertHeading: expertHeadingRes?.data?.[0] || null,
      sitewideBanner: formatSiteWideBanner(
        sitewideBannerRes?.data?.[0],
        'home',
      ),
      homepageTopics: homepageTopics?.data ?? [],
      pregnancyWeeks: pregnancyProgress?.data?.results ?? [],
      schemaData: schemaRes?.data?.schema || '',
      footerMenu: footer?.data ?? {},
      dataNavMenuContent: cleanUpUndefined(navMenuContent) || null,
    },
  }
}

export default Home
