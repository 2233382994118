import React from 'react'

import { Button, Text } from '@hhgtech/hhg-components'
import { redirectToSignUp } from 'api/utils'
import { StyledDropDown } from 'components/atoms/dropdown'
import { useIntl } from 'react-intl'
import { IResultDataTrack } from 'utils/tracking'

import { StyledContainer } from './index.styled'

const DueDateSavingCard = ({ btnProp }: { btnProp?: IResultDataTrack }) => {
  const { formatMessage: f } = useIntl()

  const monthOptions = new Array(12)
    .fill(true)
    .map((_, i) => i + 1)
    .map((m) => ({
      label: f({
        id: 'month.'.concat(m < 10 ? `0${m}` : `${m}`),
        defaultMessage: `Month ${m}`,
      }),
      value: m < 10 ? `0${m}` : `${m}`,
    }))

  const [monthValue, setMonthValue] = React.useState({
    value: '01',
    label: f({ id: 'month.01' }),
  })

  return (
    <StyledContainer>
      <Text size="p2" type="regular">
        {f({
          id: 'dueDatePage.signUpCardTitle',
          defaultMessage: 'Do you know your due date?',
        })}
      </Text>
      <Text size="n1" type="regular">
        {f({
          id: 'dueDatePage.signUpCardNote',
        })}
      </Text>

      <StyledDropDown
        size="lg"
        id="due-date-month-saving"
        groupedWithLabel={f({ id: 'dueDatePage.signUpCardMonthSelect' })}
        options={monthOptions}
        placeholder=""
        value={monthValue}
        onChange={(o) =>
          setMonthValue({
            label: o.label as string,
            value: o.value as string,
          })
        }
      />

      <Button
        {...btnProp}
        theme="marryBaby"
        size="lg"
        color="primary"
        className="btn"
        onClick={() => redirectToSignUp('due-date')}
      >
        {f({ id: 'footer.signUp', defaultMessage: 'Sign Up Now' })}
      </Button>
    </StyledContainer>
  )
}

export { DueDateSavingCard }
