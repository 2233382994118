import { CSSProperties, useState, useEffect } from 'react'

import { lazyLoadInstance } from 'utils/lazyLoad'

import { StyledUserAvatar } from './index.styled'

export type Props = {
  style?: CSSProperties
  className?: string
  avatar?: string
  username?: string
}

const UserAvatar = ({ className, style, avatar, username }: Props) => {
  const [tempAvatar, setTempAvatar] = useState(false)
  useEffect(() => {
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }
  }, [avatar])
  return (
    <StyledUserAvatar className={className} style={style}>
      {avatar && !tempAvatar ? (
        <img
          loading="lazy"
          // src={avatar}
          data-src={avatar}
          className="avatar lazy"
          alt={username || ''}
          onError={() => {
            // e.currentTarget.onerror = null
            setTempAvatar(true)
          }}
        />
      ) : (
        <div className="avatar avatar-default">
          {username && username?.length > 0 ? username[0].toUpperCase() : 'A'}
        </div>
      )}
    </StyledUserAvatar>
  )
}

export { UserAvatar }
