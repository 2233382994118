import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledPopup = styled.div`
  position: fixed;
  left: 50%;
  width: 343px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 0px 4px ${(props: ThemeProps) => props.theme.colors.gray200};
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;

  ${MediaQueries.mbUp} {
    width: 450px;
  }

  &[data-animation='true'] {
    transition: all 0.5s ease-in-out;
  }

  &[data-open='true'] {
    z-index: 200;
    top: 50%;
    opacity: 1;
    visibility: visible;
  }

  &[data-open='false'] {
    top: -50%;
  }

  .popup-content {
    position: relative;
    z-index: 111;
    height: 100%;
    .button-see-more {
      margin: 32px 0;
      text-align: center;
    }
  }

  .close-button {
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .main-info {
    margin-bottom: 32px;
    background: url(./images/expert-background.svg) no-repeat center top / cover;
    border-radius: 6px;
    .border-banner {
      position: absolute;
      width: 100%;
      height: 200px;
      margin-top: 96px;
      background: #ffffff;
      border-radius: 20px 20px 0px 0px;
    }

    .avatar-title {
      position: relative;
      width: 100%;
      padding-top: 46px;
      text-align: center;
    }
  }
`

export const StyledPopupWrapper = styled.div`
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(38, 38, 38, 0.3);

  &[data-open='false'] {
    display: none;
  }
`

export const StyledProfileImage = styled.div`
  .image-container {
    .badge-avatar {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  text-align: center;
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  .badge-avatar {
    width: 24px;
    height: 24px;
  }
`

export const StyledTitleContainer = styled.div`
  position: relative;
  z-index: 9;
  .specialty {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  padding: 0px 16px;
  text-align: center;
  .specialty {
    justify-content: center;
  }

  .experience {
    padding: 0px 15px;
    text-align: left;
  }
`

export const StyledSeparator = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.4em;
  color: ${(props: ThemeProps) => props.theme.colors.neutral300};
`
export const StyledDotSeparator = styled.div`
  display: inline-block;
  width: 3px;
  height: 3px;
  margin: 0;
  background-color: ${(props: ThemeProps) => props.theme.colors.gray400};
  border-radius: 50%;
`

export const StyledItem = styled.div`
  display: flex;
  margin-top: 8px;

  .bullet-point {
    width: 24px;
    height: 24px;
  }
`
