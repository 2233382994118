import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledTabs = styled.ul`
  display: inline-flex;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
`

export const StyledTab = styled.li`
  display: flex;

  align-items: center;
  padding-bottom: 4px;

  ${MediaQueries.mbDown} {
    height: 36px;
  }

  ${MediaQueries.mbUp} {
    height: 35px;
  }

  p {
    color: #8f909a;
    white-space: nowrap;
  }

  &:not(:last-child) {
    margin-right: 46px;
  }

  &[data-is-tab-active] {
    position: relative;
    &:after {
      position: absolute;
      top: 86%;
      left: 50%;
      display: inline-block;
      width: 16px;
      height: 2px;
      background: ${(props: ThemeProps) => props.theme.mbColors.pink};
      border-radius: 2px;
      content: '';
      transform: translateX(-50%);
    }
    p {
      color: ${(props: ThemeProps) => props.theme.mbColors.dark};
    }
  }

  &:not([data-is-tab-active]) {
    cursor: pointer;
  }
`
export const StyledTabContent = styled.div`
  display: flex;
  align-items: center;
`
export const StyledIcon = styled.div`
  margin-right: 8px;
`
