import { CSSProperties } from 'react'

import { Text } from '@hhgtech/hhg-components'
import { useDragScroll } from 'hooks/useDragScroll'

import { StyledTagTopic } from './index.styled'

export type Props = {
  onClick?: () => void
  className?: string
  style?: CSSProperties
  topics: {
    id: string
    name: string
    slug: string
  }[]
}

const TagTopic = ({ topics, onClick, className, style }: Props) => {
  const { sliding, mouseDownHandler, sliderRef: slider } = useDragScroll()

  return (
    <StyledTagTopic
      ref={slider}
      onClick={onClick}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => mouseDownHandler(e)}
      className={`${className} ${sliding ? 'sliding' : ''}`}
      style={style}
    >
      {topics.map((topic, index) => (
        <a
          className="tag-link-item"
          key={index}
          href={`/community/${topic.slug}`}
        >
          <Text size="p3">{topic.name}</Text>
        </a>
      ))}
    </StyledTagTopic>
  )
}

export { TagTopic }
