import { useState, useRef } from 'react'

export const useDragScroll = () => {
  const [sliding, setSliding] = useState(false)
  const slider = useRef<HTMLDivElement>(null)
  const pos = useRef<{
    left: number
    x: number
  } | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const mouseDownHandler = function (e: React.MouseEvent<HTMLDivElement>) {
    // Change the cursor and prevent user from selecting the text
    if (slider.current) {
      timeoutRef.current = setTimeout(() => {
        setSliding(true)
      }, 500)
      pos.current = {
        left: slider.current.scrollLeft,
        x: e.clientX,
      }

      const mouseMoveHandler = function (e: MouseEvent) {
        // How far the mouse has been moved
        const currentPos = pos.current
        if (currentPos) {
          const dx = e.clientX - currentPos.x
          // const dy = e.clientY - pos.y;

          // Scroll the element
          // ele.scrollTop = pos.top - dy;
          if (slider.current) slider.current.scrollLeft = currentPos.left - dx
        }
      }
      const mouseUpHandler = function () {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = undefined
        }
        setSliding(false)
        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
      }
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }
  }

  return {
    sliding,
    mouseDownHandler,
    sliderRef: slider,
    pos,
  }
}
