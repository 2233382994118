import DOMPurify from 'isomorphic-dompurify'

export const sanitize = (html: string) => {
  if (!html) return ''
  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['name', 'target', 'data-url'],
    ADD_TAGS: ['mention'],
  })
}

export const EncodeUrlRegexMap = {
  edit: /<mocka ([^>]*)data-url="true"[^<>]*>(.*?)<\/mocka>/gim,
  url: /((?:=")|(?:data-url="true">)|)(https?:\/\/(www\.)?(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))/gim,
  encodedUrl: /\[a\+href="([^<"]+)"(?:\+text=\"([^<"]+)")?\]/gim,
  editorMention: /<mention [^>]*data-id="([^<"]+)"[^>]*>([^<]+)<\/mention>/gim,
  savedMention: /\[mention\+id="([^<"]+)"([^\]]*)\]/gim,
  rawUrl: /^(https?:\/\/(www\.)?(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))$/gim,
  optionalProtocol: /^((https?:\/\/)?(www\.)?(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))$/gim,
  encodedUrl2: /<a [^>]*href="([^>"]+)"[^>]*>([^<]+)<\/a>/gim,
}

export const getAnchorHtml = ({
  url,
  text,
  method,
}: {
  url: string
  text: string
  method: string
}) => {
  // remove white space before and after link element, put them outside of current tag
  const trimStartIndex = text.indexOf(text.trimStart())
  const trimEndIndex = text.trimEnd().length
  const preSpaces = text.substring(0, trimStartIndex)
  const trimmedText = text.substring(trimStartIndex, trimEndIndex)
  const postSpaces = text.substring(trimEndIndex)
  if (method === 'edit') {
    return `${preSpaces}<mocka data-href="${url}" data-url="true">${trimmedText}</mocka>${postSpaces}`
  }
  if (method === 'display') {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return `${preSpaces}<a href="${url}" target="_blank">${trimmedText}</a>${postSpaces}`
    }
  }
  return text
}

export const decodePostUrl = (s: string, method: string) =>
  s
    .replace(EncodeUrlRegexMap.encodedUrl, (_match, p1, p2) => {
      const text = p2 || p1
      return getAnchorHtml({
        text,
        url: p1,
        method,
      })
    })
    .replace(EncodeUrlRegexMap.encodedUrl2, (_match, p1, p2) => {
      const text = p2 || p1
      return getAnchorHtml({
        text,
        url: p1,
        method,
      })
    })
export const decodeMention = (
  s: string,
  users: {
    id: number
    name: string
  }[] = [],
) =>
  s.replace(EncodeUrlRegexMap.savedMention, (_, p1, p2) => {
    const foundUser = users.find((u) => String(u.id) === p1)
    if (!foundUser) {
      if (!p2) return ''
      let tempName = ''
      ;(p2 as string).replace(/\+name="([^<"]+)"/i, (m, _p1) => {
        tempName = _p1
        return m
      })
      return `<mention data-id="${p1}" data-name="${tempName}">${tempName}</mention>`
    }
    return `<mention data-id="${p1}" data-name="${foundUser.name}">${foundUser.name}</mention>`
  })
