import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyledArticlesContainer = styled.div`
  margin-top: 0px;

  background-color: ${(props: ThemeProps) => props.theme.mbColors.lightPink};
`

export const StyledArticleContainer = styled.div`
  .banner {
    width: 100%;
    min-height: 192px;
  }
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  ${deviceLayout.pcLayout`
    padding: 24px 0px 24px 0;
    &#desktop-first-article-block {
      padding-top: 0;
      .banner {
        height: unset;
        max-height: unset;
      }
    }
  `}

  ${deviceLayout.spLayout`
    &#mobile-first-article-block .banner {
      margin-top: -16px;
      height: unset;
      max-height: unset;
    }  
  `}
`

export const StyledSmallArticleContainer = styled.div`
  padding: 16px;

  ${deviceLayout.spLayout`
    padding: 0;
  `}
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

// desktop layout
export const StyledArticlesDesktopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 0 16px;

  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

export const StyledHeroContainer = styled.div`
  display: flex;
  width: 66%;
  flex-direction: column;
  align-self: stretch;
  justify-content: stretch;

  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  justify-self: stretch;

  & > div > div {
    height: 100%;
  }

  .banner {
    min-height: 312px;
  }
`

export const StyledSecondaryContainer = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }

  & > div:not(:first-child) {
    padding-left: 12px;
  }

  .banner {
    height: 212px !important;
    min-height: unset;
  }
`

export const StyledSubHeroContainer = styled.div`
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;

  padding-top: 8px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  .banner {
    min-height: 150px;
  }

  .content .new .text {
    margin: 6px 0;
  }

  & > :last-child {
    flex: 1;
  }
`

export const StyledArticleDesktopContainer = styled.div`
  padding: 16px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

export const StyledSmallArticleDesktopContainer = styled.div`
  /* padding: 20px 16px 20px 0; */
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

export const StyledSubColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;

  align-self: stretch;
  justify-content: flex-start;

  margin-left: 24px;

  & > div:nth-child(2) {
    margin-top: 24px;
  }
`
export const StyledMobileDueDateContainer = styled.div`
  & .due-date-calc-content {
    padding: 24px 16px;
    background-color: ${(props: ThemeProps) => props.theme.colors.white};

    & > div:last-child {
      margin-top: 16px;
    }
  }
`
export const StyledSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  & .sub-articles-group {
    &:not([is-mobile='true']) {
      display: flex;
      & > div {
        width: 50%;
        &:first-child {
          margin-right: 24px;
        }
      }
    }

    & > div {
      margin-top: 30px;
    }
  }
`

export const StyledSkeletonArticle = styled.div`
  &.mobile-skeleton-article {
    display: flex;
    height: 100px;

    & > div {
    }

    & > div:first-child {
      width: 100px;
    }

    & > div:last-child {
      flex: 1;
      & > span:nth-child(2) > span {
        margin: 4px 0;
      }
    }
  }

  & > span > span {
    margin: 4px 0;
  }
`

export const StyledMobileArticlesBlock = styled.div`
  padding: 24px 16px 0;
  background-color: ${(props: ThemeProps) => props.theme.mbColors.lightPink};

  & > div:first-child {
    border-radius: 16px 16px 0 0;
  }
  & > div:last-child {
    border-radius: 0 0 16px 16px;
  }
`
