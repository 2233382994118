import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import { ExpandedCard } from 'components/molecules/expandedCard'
import styled from 'styled-components'

export const StyledTopicsDropdownContainer = styled.div`
  ${MediaQueries.mbDown} {
    padding: 24px 16px;
    border-radius: 16px 16px 0 0;
  }

  & .topic-card:not(:first-child) {
    margin: 16px 0 0;
  }
`

export const StyledExpandedCard = styled(ExpandedCard)`
  overflow: hidden;
  border-radius: 16px;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;

  & .expandable-icon {
    visibility: hidden;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  height: 80px;
  align-items: center;

  & .img-container {
    flex-shrink: 0;
    margin-left: 16px;
    transition: background-color 0.4s ease;
  }

  & > p {
    flex: 1;
    margin-left: 16px;
    transition: all 0.2s ease;
    &.expanded-title {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  & .right-chevron-icon {
    flex-shrink: 0;
    margin-right: 16px;
    transition: transform 0.2s ease-in-out;
  }
`

export const StyledContentWrapper = styled.div`
  padding: 0 16px 24px;

  & .description {
    font-weight: 400 !important;
  }

  & .healthtool-button {
    width: 100%;

    & > span {
      display: flex;
      justify-content: center;
      & img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        object-fit: contain;
      }
    }
  }

  & .read-article {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;

    p {
      color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    }
    & span {
      margin-left: 4px;
    }
  }
`
