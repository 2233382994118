import { CSSProperties, useMemo, useState } from 'react'

import { PopupExpert } from 'components/molecules/popupExpert'
// import { useAuthRequiredAction } from 'hooks/useAuthRequiredAction'
import { TogetherPostMention, TogetherRole, UserInfo } from 'interfaces/types'
import { useIntl } from 'react-intl'
import { decodeMention, decodePostUrl, sanitize } from 'utils/togetherPost'

import { StyledCardPostContent } from './index.styled'

export type Props = {
  description?: string
  replyingTo?: string
  disableShortenContent?: boolean
  className?: string
  style?: CSSProperties
  mentionedUser?: Omit<UserInfo, 'role'> & { role: TogetherRole }
  mentions?: TogetherPostMention[]
}

export const CardPostContent = ({
  className,
  style,
  replyingTo,
  mentionedUser,
  description: rawDescription,
  disableShortenContent,
  mentions = [],
}: Props) => {
  const [showAll] = useState(false)
  const { formatMessage: f } = useIntl()
  // const { authActionWrapper } = useAuthRequiredAction()
  const [openPopupExpert, setOpenPopupExpert] = useState(false)
  const description = decodeMention(
    decodePostUrl(rawDescription || '', 'display'),
    mentions,
  )
  const shortenSanitizedContentData = useMemo(() => {
    const sanitizedContent = sanitize(description || '')
    if (disableShortenContent || showAll)
      return {
        showContent: sanitizedContent,
        hasSeeMore: false,
      }
    const firstRound = sanitize((description || '').slice(0, 200))
    const newLineCompensateLength =
      200 - ((firstRound.match(/<\/div>/g) || []).length - 1) * 27
    let shortenFirstRound = (firstRound || '').slice(
      0,
      newLineCompensateLength < 40 ? 40 : newLineCompensateLength,
    )
    // remove < if we cut off right before start of a new html tag
    if (shortenFirstRound.endsWith('<'))
      shortenFirstRound = shortenFirstRound.slice(0, -1)
    if (shortenFirstRound.endsWith('</'))
      shortenFirstRound = shortenFirstRound.slice(0, -2)

    const secondRound = sanitize(shortenFirstRound)
    // don't show see more if not much difference
    const hasSeeMore = secondRound.length < sanitizedContent.length - 10
    return {
      showContent: hasSeeMore ? secondRound : sanitizedContent,
      hasSeeMore,
    }
  }, [disableShortenContent, showAll, description])

  const replyName = mentionedUser?.name || replyingTo
  const hasExpert =
    mentionedUser?.role === 'expert' && !!mentionedUser?.partnerId
  return (
    <>
      {hasExpert && (
        <PopupExpert
          isOpen={openPopupExpert}
          onClose={() => setOpenPopupExpert(false)}
          expertId={String(mentionedUser?.partnerId)}
        />
      )}
      <StyledCardPostContent
        className={`${className} no-replace-click`}
        style={style}
      >
        {replyName && (
          <span
            onClick={() => hasExpert && setOpenPopupExpert(true)}
            className="post-content-replying"
          >
            @{replyName}
          </span>
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: shortenSanitizedContentData.showContent,
          }}
          className={`post-content ${
            shortenSanitizedContentData.hasSeeMore ? 'has-see-more' : ''
          }`}
        />
        {shortenSanitizedContentData.hasSeeMore && !showAll && (
          <span>
            {`... `}
            <span
              onClick={(e) => {
                e.preventDefault()
                // authActionWrapper(() => setShowAll(true))
              }}
              className="see-more-text"
            >
              {f({ id: 'community.readMore' })}
            </span>
          </span>
        )}
      </StyledCardPostContent>
    </>
  )
}
