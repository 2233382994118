import { MediaQueries, theme } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  .countdown {
    display: inline-flex;
    gap: 16px;
  }
`

export const StyledCountdownItem = styled.div`
  text-align: center;
  span {
    display: block;
  }

  .countdown--number {
    width: 64px;
    height: 48px;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 8px;
    letter-spacing: -0.8px;
    color: white;
    background-color: #ffa5bf;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${MediaQueries.mbDown} {
      width: 44px;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.6px;
    }
  }
  .countdown--label {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: ${theme.colors.gray800};
    ${MediaQueries.mbDown} {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.2px;
    }
  }
`
