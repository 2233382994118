import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyledContainer = styled.div`
  padding: 80px 0;

  ${deviceLayout.spLayout`
    padding-top: 42px;
    padding-bottom: 70px;
  `}

  .trust-list .item-grid {
    width: 100%;
  }

  .trust-list .bullet-nav {
    bottom: -10%;
  }

  & .single-trust-card {
    &:first-child {
      background-color: ${(props: ThemeProps) =>
        props.theme.mbColors.toneGreen};
    }

    &:nth-child(2) {
      background-color: ${(props: ThemeProps) =>
        props.theme.mbColors.toneYellow};
    }
    &:nth-child(3) {
      background-color: ${(props: ThemeProps) => props.theme.mbColors.toneBlue};
    }
    &:nth-child(4) {
      background-color: ${(props: ThemeProps) => props.theme.mbColors.tonePink};
    }
  }
`

export const StyledHeader = styled.div`
  margin-bottom: 48px;

  ${deviceLayout.spLayout`
      margin-bottom: 24px;
  `}

  & .title {
    text-align: center;
  }

  & .description {
    max-width: 504px;
    margin: 16px auto 0;
    text-align: center;

    ${deviceLayout.spLayout`
      margin-top: 8px;
    `}
  }
`

export const StyledCaptionContainer = styled.div`
  margin-top: 8px;
`

export const StyledElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  border-radius: 16px;

  ${deviceLayout.spLayout`
    width: 248px;
  `}

  & img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
`

export const StyledCard = styled.div`
  width: 100%;
  min-height: 100px;
  flex: 1;
  margin: 8px 0 12px;
`

export const StyledCardHeading = styled.div`
  display: flex;
  flex-direction: flex-start;
  align-items: flex-start;

  & > * {
    word-break: break-word;
  }
`

export const StyledCardCaption = styled.div`
  margin-top: 8px;

  & > p {
    word-break: break-word;
  }
`

export const StyledMobileTrustCard = styled.div`
  & .trust-list {
    & .scroll-track {
      display: flex;
      width: 1072px;
      justify-content: space-evenly;

      & .single-trust-card {
        flex-shrink: 0;
      }
    }
  }
`

export const StyledDesktopTrustCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px;

  & .single-trust-card {
    flex: 1;

    &:not(:first-child) {
      margin-left: 24px;
    }
  }
`
