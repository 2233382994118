import { useState, useEffect, RefObject } from 'react'

function useIsInView(ref: RefObject<HTMLElement>) {
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    const checkIsInView = () => {
      const element = ref.current
      if (element) {
        const boundingBox = element.getBoundingClientRect()
        const isVisible =
          boundingBox.top >= 0 &&
          boundingBox.left >= 0 &&
          boundingBox.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          boundingBox.right <=
            (window.innerWidth || document.documentElement.clientWidth)

        setIsInView(isVisible)
      }
    }

    const handleScroll = () => {
      checkIsInView()
    }

    // Initial check
    checkIsInView()

    // Listen to scroll events to recheck on scroll
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ref])

  return isInView
}

export default useIsInView
