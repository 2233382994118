import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyleRecommendedTopics = styled.div`
  padding: 80px 0px;
  background-color: ${(props: ThemeProps) => props.theme.mbColors.lightMint};

  ${deviceLayout.spLayout`
    padding: 24px 0;
    border-radius: 16px 16px 0 0;
  `}

  & .category-card {
    border: none;
    border-radius: 16px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  }

  & div.banner {
    background-color: ${(props: ThemeProps) => props.theme.colors.white};
  }
`

export const StyleRecommendedTopicsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  ${deviceLayout.spLayout`
    justify-content: space-between;
    padding: 0;

    & > a {
      text-decoration: none;
      & > p {
        color: ${(props: ThemeProps) => props.theme.mbColors.pink};
      }
    }
  `}
`

export const StyledCategoryListContainer = styled.div`
  ${deviceLayout.pcLayout`
    margin-top: 24px;
  `}
`

export const StyledDesktopCategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 16px;
  justify-content: center;
  // grid-gap: 24px 24px;
  // grid-template-columns: 1fr 1fr 1fr;
  .category-desktop {
    width: calc((100% - 72px) / 3);
    max-width: unset;
    height: 140px;
    min-height: unset;
    flex-direction: row;
    align-items: center;

    & .banner {
      width: unset;
      height: unset;
      margin-right: 16px;
      margin-left: 24px;
    }

    & .category_name {
      font-family: Krub;
      font-size: ${(props: ThemeProps) => props.theme.sizes.fsHeading5};
      font-weight: ${(props: ThemeProps) => props.theme.sizes.fwSemiBold};
      letter-spacing: -0.2px;
      text-align: left;
    }
  }
`

export const StyledMobileCategoriesContainer = styled.div`
  display: flex;
  margin: 14px 0 0;

  & > * {
    flex: 1;
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  & .category-mobile {
    height: 132px;
    flex: 1;

    & .banner {
      height: unset;
      & > div {
        margin: 8px auto;
      }
    }

    & .content {
      -webkit-line-clamp: 3;
      line-clamp: 3;
      & .category_name {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 20px;
      }
    }
  }
`

export const StyledSpotlightSeeAll = styled.div`
  text-align: center;

  a {
    text-decoration: none;

    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    border: 2px solid ${(props: ThemeProps) => props.theme.mbColors.pink};
    border-radius: 16px;
    padding: 14px;
    text-align: center;
    width: 80%;
    max-width: 312px;
  }
`
