import { useEffect, useState } from 'react'

import { theme } from '@hhgtech/hhg-components'
import { formatCommunityPost } from 'api/dataTransform'
import { PATHS } from 'api/paths'
import { callApiWithOptionalAuth, getTogetherPath } from 'api/utils'
import { Container } from 'components/atoms'
import { Heading, Text } from 'components/atoms/text'
import { DropdownTopic, HomePageCommunityPostApi } from 'interfaces/types'
import { useIntl } from 'react-intl'

import { CardListCommunity } from '../cardListCommunity'
import { TopicsDropdown } from '../homepageTopicsDropdown'
import { CommunityPost } from './CommunityPost'
import { ImagePreviewPopupProviderWrapper } from './CommunityPost/imagePreviewPopup'
import {
  StyledHomepageCommunityContainer,
  StyledContent,
  StyledMainColumn,
  StyledSubColumn,
  StyledHeader,
  StyledGroupContainer,
  StyledPostContainer,
} from './index.styled'

type Props = {
  isMobile: boolean
  topics: DropdownTopic[]
}

const HomePageCommunity = ({ isMobile, topics }: Props) => {
  const { formatMessage: f } = useIntl()

  const [posts, setPosts] = useState<HomePageCommunityPostApi[]>([])
  useEffect(() => {
    callApiWithOptionalAuth(
      getTogetherPath(PATHS.GET_TOGETHER_HOMEPAGE_POSTS_AUTH),
      getTogetherPath(PATHS.GET_TOGETHER_HOMEPAGE_POSTS),
      'GET',
    ).then((res) => {
      setPosts(res?.data?.posts?.map(formatCommunityPost) || [])
    })
  }, [])

  return (
    <StyledHomepageCommunityContainer>
      <ImagePreviewPopupProviderWrapper>
        <Container>
          <div>
            <StyledHeader>
              <Heading type={isMobile ? 'h3' : 'h2'}>
                {f({ id: 'community' })}
              </Heading>
              <a href="/community">
                <Text type="bodytext-2" color={theme.mbColors.pink}>
                  {f({
                    id: 'home.seeMore',
                    defaultMessage: 'View All',
                  })}
                </Text>
              </a>
            </StyledHeader>
            <StyledGroupContainer>
              <CardListCommunity isMobile={isMobile} />
            </StyledGroupContainer>
          </div>
          <StyledContent>
            <StyledMainColumn>
              <StyledPostContainer>
                {posts.slice(0, 3).map((p, i) => (
                  <CommunityPost
                    className="community-post"
                    key={i}
                    isMobile={isMobile}
                    {...p}
                  />
                ))}
              </StyledPostContainer>
            </StyledMainColumn>

            {!isMobile && (
              <StyledSubColumn>
                <TopicsDropdown topics={topics} />
              </StyledSubColumn>
            )}
          </StyledContent>
        </Container>
      </ImagePreviewPopupProviderWrapper>
    </StyledHomepageCommunityContainer>
  )
}

export { HomePageCommunity }
