import React, { useEffect, useMemo } from 'react'

import { CategoryCard, Text } from '@hhgtech/hhg-components'
import { Container } from 'components/atoms/container'
import { Heading } from 'components/atoms/text'
import { StyledIconContainer } from 'components/molecules/iconContainer'
import { domainLocales } from 'domainLocales'
import { useScreenSize } from 'hooks'
import { LOCALE_SPECS } from 'interfaces/constants'
import { CategoriesLayout, Category } from 'interfaces/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { lazyLoadInstance } from 'utils/lazyLoad'
import { genPropTrackV2, TRACK_GA } from 'utils/tracking'

import {
  StyledMobileCategoriesContainer,
  StyledDesktopCategoriesContainer,
  StyledCategoryListContainer,
  StyleRecommendedTopics,
  StyleRecommendedTopicsHeaderContainer,
} from './index.styled'

export type Props = {
  categoryLoading: boolean
  onCategoryClick?: (l: string) => void
  categories: Category[]
  isMobile?: boolean
}

const CategoriesMobileSkeleton = (props: { n: number }) => (
  <StyledMobileCategoriesContainer>
    {Array.from({ length: props.n }).map((_, i) => (
      <Skeleton key={i} height={132} />
    ))}
  </StyledMobileCategoriesContainer>
)

const CategoriesDesktopSkeleton = (props: { n: number }) => (
  <StyledDesktopCategoriesContainer>
    {Array.from({ length: props.n }).map((_, i) => (
      <Skeleton key={i} height={140} />
    ))}
  </StyledDesktopCategoriesContainer>
)

const RecommendedTopics = ({
  isMobile,
  categoryLoading,
  categories,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const { screenWidth } = useScreenSize()
  const { locale } = useRouter()
  const sL = locale || 'vi-VN' // standardized locale
  const categoriesType = (LOCALE_SPECS[locale || 'vi-VN'].CATEGORIES_TYPE ||
    'long') as CategoriesLayout

  const numberOfCategoryDisplayed = useMemo(
    () => (screenWidth ? Math.floor((screenWidth - 16) / 119) : 3),
    [screenWidth],
  )

  useEffect(() => {
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }
  }, [])

  return categories.length > 0 ? (
    <StyleRecommendedTopics>
      <Container>
        <StyleRecommendedTopicsHeaderContainer>
          <Heading type={isMobile ? 'h3' : 'h2'}>
            {f({
              id: 'home.recommendTopics',
              defaultMessage: 'Health Categories',
            })}
          </Heading>
          {isMobile && (
            <Link href="/categories">
              <a
                data-event-category="Recommended Topics"
                data-event-action="See All Click"
                data-event-label={`https://${domainLocales[sL]}/categories`}
              >
                <Text size="c1">
                  {f({
                    id: 'categoryPage.seeAll',
                    defaultMessage: 'View all',
                  })}
                </Text>
              </a>
            </Link>
          )}
        </StyleRecommendedTopicsHeaderContainer>
      </Container>

      <StyledCategoryListContainer data-layout-type={categoriesType}>
        <Container>
          {categoryLoading || categories.length === 0 ? (
            isMobile ? (
              <CategoriesMobileSkeleton n={numberOfCategoryDisplayed} />
            ) : (
              <CategoriesDesktopSkeleton n={numberOfCategoryDisplayed} />
            )
          ) : isMobile ? (
            <StyledMobileCategoriesContainer>
              {categories
                .slice(0, numberOfCategoryDisplayed)
                .map((category, categoryIndex) => (
                  <CategoryCard
                    {...genPropTrackV2(
                      TRACK_GA.CATEGORY,
                      `https://${domainLocales[sL]}${category.permalink}`,
                    )}
                    category={category}
                    className="category-card category-mobile"
                    key={category.termId + '-' + categoryIndex}
                    imgBanner={
                      <StyledIconContainer
                        iconSize={24}
                        size={40}
                        backgroundColor={category.thumnailColor}
                      >
                        <img
                          loading="lazy"
                          src={
                            category.iconUrl ||
                            '/icons/category_icon_fallback.svg'
                          }
                          alt={category.displayName}
                          className="lazy"
                        />
                      </StyledIconContainer>
                    }
                    label={category.displayName}
                  />
                ))}
            </StyledMobileCategoriesContainer>
          ) : (
            <StyledDesktopCategoriesContainer>
              {categories.map((category, categoryIndex) => (
                <CategoryCard
                  {...genPropTrackV2(
                    TRACK_GA.CATEGORY,
                    `https://${domainLocales[sL]}${category.permalink}`,
                  )}
                  category={category}
                  className="category-card category-desktop"
                  key={category.termId + '-' + categoryIndex}
                  imgBanner={
                    <StyledIconContainer
                      iconSize={40}
                      size={64}
                      backgroundColor={category.thumnailColor}
                    >
                      <img
                        loading="lazy"
                        src={
                          category.iconUrl ||
                          '/icons/category_icon_fallback.svg'
                        }
                        alt=""
                      />
                    </StyledIconContainer>
                  }
                  label={category.displayName}
                />
              ))}
            </StyledDesktopCategoriesContainer>
          )}
        </Container>
      </StyledCategoryListContainer>
    </StyleRecommendedTopics>
  ) : (
    <></>
  )
}

export { RecommendedTopics }
