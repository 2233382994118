import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledUserAvatar = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 20px;

  img {
    border-radius: 50%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .avatar-default {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: ThemeProps) =>
      props.theme.colors.secondaryBase};
    border-radius: 50%;
    color: white;
    font-size: 1em;
    font-weight: bold;
  }
`
