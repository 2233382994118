import { Dropdown, ThemeProps } from '@hhgtech/hhg-components'
import { Z_INDEX } from 'interfaces/constants'
import styled, { css } from 'styled-components'

export const StyledDropDown = styled(Dropdown)`
  border-color: ${(props: ThemeProps) => props.theme.mbColors.lightGray};
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
  border-radius: 16px;
  transition: all 0.2s ease;
  &:hover {
    border-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    box-shadow: none;
  }

  & > ul {
    z-index: ${Z_INDEX.menuSearchPopup};
    max-height: 300px;
    overflow-y: auto;
  }

  ${(props) =>
    props.groupedWithLabel &&
    css`
      & > div:first-child {
        color: ${(props: ThemeProps) => props.theme.mbColors.midGray};
        font-size: 12px;
      }
    `}

  &[data-dropdown-open='true'] {
    border-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
  }
`
