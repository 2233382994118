import { MediaQueries } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyledHomepageSpotlight = styled.div`
  ${MediaQueries.mbDown} {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    padding: 16px 0;
    margin-top: 40px;
  }
`

export const StyledHomepageSpotlightsWrapper = styled.div`
  display: grid;

  margin-top: 16px;
  ${MediaQueries.mbUp} {
    margin: 40px 0;
    padding: 0 16px;

    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 24px;
  }

  ${MediaQueries.mbDown} {
    justify-content: center;
    margin-bottom: 16px;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  > a {
    height: unset;
    ${MediaQueries.mbUp} {
      padding-top: 133%;
    }

    ${MediaQueries.mbDown} {
      max-width: 343px;
      max-height: 457px;
      place-self: center;
      &:before {
        content: '';
        display: block;
        padding-top: 133%;
      }
    }
  }
`

export const StyleRecommendedSpotlightHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${deviceLayout.pcLayout`
    padding: 0 16px;
  `}
`
