import { useMemo } from 'react'

import { theme } from '@hhgtech/hhg-components'
import { Container } from 'components/atoms'
import { Text as MBText, Heading as MBHeading } from 'components/atoms/text'
import { SpotlightCard } from 'components/pages/spotlight/spotlightCard'
import { checkAvaiableSpotlight } from 'components/pages/spotlight/utils'
import { domainLocales } from 'domainLocales'
import { SpotlightSummary } from 'interfaces/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

import { StyledSpotlightSeeAll } from '../homepageRecommendedTopicsCard/index.styled'
import {
  StyledHomepageSpotlight,
  StyledHomepageSpotlightsWrapper,
  StyleRecommendedSpotlightHeaderContainer,
} from './index.styled'

type Props = {
  isMobile: boolean
  spotlights: SpotlightSummary[]
}
const RecommendSpotlight = ({ isMobile, spotlights }: Props) => {
  const { formatMessage: f } = useIntl()

  const { locale } = useRouter()

  const sL = locale || 'vi-VN' // standardized locale

  const filteredSpotLights = useMemo(
    () => spotlights.filter(checkAvaiableSpotlight).slice(0, 4),
    [spotlights],
  )

  if (filteredSpotLights.length === 0) return null

  return (
    <StyledHomepageSpotlight>
      <Container>
        <StyleRecommendedSpotlightHeaderContainer>
          <MBHeading type={isMobile ? 'h3' : 'h2'} color={theme.colors.gray800}>
            {f({
              id: 'home.recommendSpotlight',
              defaultMessage: 'Spotlight',
            })}
          </MBHeading>
          {!isMobile && (
            <Link href="/spotlight/">
              <a
                data-event-category="Spotlight"
                data-event-action="See All Click"
                data-event-label={`https://${domainLocales[sL]}/spotlight`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <MBText type="bodytext-2" color={theme.mbColors.pink}>
                  {f({
                    id: 'home.seeAll',
                    defaultMessage: 'See All',
                  })}
                </MBText>
              </a>
            </Link>
          )}
        </StyleRecommendedSpotlightHeaderContainer>
        <StyledHomepageSpotlightsWrapper>
          {filteredSpotLights.map((spotlight, index) => (
            <SpotlightCard
              isMobile={isMobile}
              key={index}
              spotlight={spotlight}
              showSeeMore
            />
          ))}
        </StyledHomepageSpotlightsWrapper>
        {isMobile && (
          <StyledSpotlightSeeAll>
            <Link href="/spotlight/">
              <a
                data-event-category="Recommended Topics"
                data-event-action="See All Click"
                data-event-label={`https://${domainLocales[sL]}/spotlight`}
                style={{
                  textDecoration: 'none',
                  display: 'inline-block',
                  margin: 'auto',
                }}
              >
                {f({
                  id: 'home.seeAll',
                  defaultMessage: 'See All',
                })}
              </a>
            </Link>
          </StyledSpotlightSeeAll>
        )}
      </Container>
    </StyledHomepageSpotlight>
  )
}
export { RecommendSpotlight }
