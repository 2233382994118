import { MediaQueries } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledHomepageCommunityContainer = styled.div`
  padding: 80px 0;

  ${MediaQueries.mbDown} {
    padding: 40px 0;
  }
`

export const StyledContent = styled.div`
  display: flex;
  padding: 0 16px;
  ${MediaQueries.mbDown} {
    padding: 0 0;
  }
`

export const StyledMainColumn = styled.div`
  width: 66%;
  flex-shrink: 0;
  ${MediaQueries.mbDown} {
    width: 100%;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  ${MediaQueries.mbDown} {
    margin-bottom: 12px;
  }

  ${MediaQueries.mbUp} {
    padding: 0 16px;
  }

  a {
    text-decoration: none;
  }
`

export const StyledSubColumn = styled.div`
  flex: 1;
  padding-left: 24px;
`

export const StyledGroupContainer = styled.div`
  margin-bottom: 40px;

  ${MediaQueries.mbDown} {
    overflow: auto;
    margin: 0 -16px 24px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .community-wrapper {
    display: flex;

    ${MediaQueries.mbDown} {
      padding: 2px 0;
    }

    .card-wrapper {
      flex: 1;

      ${MediaQueries.mbUp} {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }

      ${MediaQueries.mbDown} {
        padding: 0 8px;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }

  .community-card {
    ${MediaQueries.mbDown} {
      min-width: 240px;
    }

    &:not(:first-child) {
      margin-left: 24px;
    }
  }
`

export const StyledPostContainer = styled.div`
  .community-post {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`
