import React, { useEffect } from 'react'

import { Text } from '@hhgtech/hhg-components'
import { Container } from 'components/atoms/container'
import { Heading } from 'components/atoms/text'
import { ProgressScrollList } from 'components/molecules/progressScrollList'
import { MedicalReview, MedicalReviewItem } from 'interfaces/types'
import { lazyLoadInstance } from 'utils/lazyLoad'

import {
  StyledCard,
  StyledCardCaption,
  StyledCardHeading,
  StyledContainer,
  StyledDesktopTrustCard,
  StyledElementContainer,
  StyledHeader,
  StyledMobileTrustCard,
} from './index.styled'

export type Props = {
  medicalReviewed: MedicalReview
  isMobile?: boolean
  className?: string
}

type CardProps = {
  card: MedicalReviewItem
  cardIndex: number
}

const Card = ({ card, cardIndex }: CardProps) => {
  return (
    <StyledElementContainer className="single-trust-card">
      <img
        loading="lazy"
        alt={card.title}
        src={card.imageUrl || '/images/trustCardSmartHome.svg'}
      />
      <StyledCard data-is-card-alternative={cardIndex % 2 === 0}>
        <StyledCardHeading>
          <Heading type="h4">{card.title}</Heading>
        </StyledCardHeading>
        <StyledCardCaption>
          <Text size="n1">{card.description}</Text>
        </StyledCardCaption>
      </StyledCard>
    </StyledElementContainer>
  )
}

const TrustCard = ({ className, medicalReviewed, isMobile }: Props) => {
  if (!medicalReviewed.title) {
    return <></>
  }

  useEffect(() => {
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }
  }, [])

  return (
    <StyledContainer className={className}>
      <StyledHeader>
        <Container>
          <Heading className="title" type={isMobile ? 'h3' : 'h2'}>
            {medicalReviewed.title}
          </Heading>
          <Text className="description" size="p4">
            {medicalReviewed.description}
          </Text>
        </Container>
      </StyledHeader>

      {isMobile ? (
        <StyledMobileTrustCard>
          <ProgressScrollList numberOfDots={4} className="trust-list">
            {medicalReviewed.items.map((card, cardIndex) => (
              <Card
                card={card}
                key={`${cardIndex}-mb`}
                cardIndex={cardIndex + 1}
              />
            ))}
          </ProgressScrollList>
        </StyledMobileTrustCard>
      ) : (
        <Container>
          <StyledDesktopTrustCard>
            {medicalReviewed.items.map((card, cardIndex) => (
              <Card
                card={card}
                key={`${cardIndex}-pc`}
                cardIndex={cardIndex + 1}
              />
            ))}
          </StyledDesktopTrustCard>
        </Container>
      )}
    </StyledContainer>
  )
}

export { TrustCard }
