import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledCommunityPostCardContainer = styled.div`
  padding: 16px;
  border: 1px solid ${(props: ThemeProps) => props.theme.mbColors.lightGray};
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .header {
    display: flex;

    .header__text {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      > div {
        display: flex;
      }

      .time {
        position: relative;
        margin-left: 20px;
        line-height: 26px;
        &:before {
          position: absolute;
          top: 12px;
          left: -10px;
          width: 3px;
          height: 3px;
          background-color: ${(props: ThemeProps) =>
            props.theme.mbColors.midGray};
          border-radius: 50%;
          content: '';
        }
      }

      .bold {
        font-weight: 600;
      }
    }

    .more-icon {
      width: 18px;
      height: 18px;
      margin-left: auto;
    }
  }

  .post-title {
    padding: 8px 0 0;
  }

  .description {
    padding: 16px 0;

    .read-more-less {
      /* margin-left: 4px; */
      color: ${(props: ThemeProps) => props.theme.mbColors.pink};
      cursor: pointer;
    }
  }

  .post-img {
    margin-top: 8px;
    border-radius: 8px;
  }

  .count {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    border-top: 1px solid
      ${(props: ThemeProps) => props.theme.mbColors.lightGray};

    > div {
      display: inline-flex;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  }

  .image-preview-wrapper {
    width: 100%;
  }
  .post-topics {
    margin-bottom: 1rem;
  }
`

export const StyledImagePreview = styled.div`
  display: grid;
  cursor: pointer;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  .img-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 66.66%;
    border-radius: 1rem;
    grid-column: auto;
    .img-preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .img-more-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  &[data-image-count='1'] {
    .img-wrapper {
      /* padding-top: 100%; */
      grid-column: 1/3;
    }
  }
  &[data-image-count='2'] {
    .img-wrapper {
      /* padding-top: 100%; */
    }
  }
  &[data-image-count='3'] {
    grid-template-columns: 1fr 1fr 1fr;
    .img-wrapper {
      &:first-child {
        padding-top: 100%;
        grid-column: 1/3;
        grid-row: 1/3;
      }
    }
  }
  &[data-image-count='4'] {
    .img-wrapper {
      /* padding-top: 100%; */
    }
  }
  &[data-image-count='5'] {
    grid-template-columns: repeat(6, 1fr);
    .img-wrapper {
      /* padding-top: 100%; */
      grid-column: auto/ span 2;

      &:first-child {
        padding-top: 56.25%;
        grid-column: 1/4;
      }
      &:nth-child(2) {
        padding-top: 56.25%;
        grid-column: 4/7;
      }
    }
  }
`
export const StyledImagePreviewPopup = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;
  &[data-open='true'] {
    opacity: 1;
    pointer-events: auto;
  }
  .top-bar {
    position: relative;
    padding: 20px 40px;
    .icon-arrow-left {
      position: absolute;
      top: 50%;
      left: 20px;
      height: 24px;
      cursor: pointer;
      transform: translateY(-50%);
    }
  }
  .container {
    flex: 1;
    overflow-y: auto;
    .img-preview {
      width: 100%;
      min-width: 100%;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }
  }
  .preview-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s linear;
    &[data-open='true'] {
      opacity: 1;
      pointer-events: auto;
    }
    .preview-top-bar {
      padding: 1rem;
      background: white;
      .icon-close {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }
    .img-preview {
      height: 0;
      flex: 1;
      object-fit: contain;
      object-position: center;
    }
  }
`
