import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 24px 16px;
  background-color: ${(props: ThemeProps) => props.theme.mbColors.lightBlue};
  border-radius: 16px;

  & > p {
    text-align: center;
    &[data-size='p2'] {
      font-weight: 600;
      letter-spacing: -0.2px;
    }
  }

  & > button {
    width: 100%;
  }

  & > *:not(:first-child) {
    margin-top: 16px;
  }
`
