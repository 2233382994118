import React, { memo } from 'react'

import { Button } from '@hhgtech/hhg-components/components'
import { MediaQueries, theme } from '@hhgtech/hhg-components/misc'
import { Container } from 'components/atoms'
import styled from 'styled-components'

import { Countdown } from './countdown'

const LunarBannerContainer = styled.div`
  width: 100%;
  padding-top: max(31.23%, 300px);
  position: relative;
  background: url(/images/lunar-banner/spotlight1-lg.jpg) center no-repeat;
  background-size: cover;
  ${MediaQueries.tdDown} {
    padding-top: max(53.4%, 300px);
    background-image: url(/images/lunar-banner/spotlight1-md.jpg);
  }
  ${MediaQueries.mbDown} {
    padding-top: max(143.78%, 300px);
    background-image: url(/images/lunar-banner/spotlight1-sm.jpg);
  }
`

const CountdownContainer = styled(Container)`
  padding: 0 0.5rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: space-between;
  align-items: center;

  ${MediaQueries.mbDown} {
    justify-content: center;
    top: 60%;
  }
  ${MediaQueries.smbDown} {
    top: 68%;
  }
  .countdown-title {
    color: #f87298;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.8px;
    margin-bottom: 4px;
    ${MediaQueries.mbDown} {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.6px;
    }
  }
  .countdown-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    margin-bottom: 16px;
  }
`

const SeeMoreBtn = styled(Button)`
  &[data-color='primary'] {
    background-color: #f87298;
    border-color: #f87298;
    color: white;
    padding: 9px 16px;
    &:hover:not(:disabled)[data-is-loading='false'] {
      background-color: ${theme.colors.red900};
    }

    &:not(:is([disabled], [data-is-loading='true'])):active {
      background-color: ${theme.colors.red900};
    }
  }
`

// const LunarBannerImg = styled.img`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
// `

const _HomepageLunarBanner = () => {
  return (
    <LunarBannerContainer>
      <CountdownContainer>
        <div>
          <p className="countdown-title">MỪNG TẾT QUÝ MÃO !</p>
          <p className="countdown-description">
            Cùng Marry Baby đếm ngược tới Tết nào! Chỉ còn...
          </p>
          <Countdown
            date={'2023-01-21T17:00:00.000Z'}
            style={{ marginBottom: 16 }}
          />
          <a
            href={'/spotlight/tet-quy-mao-2023'}
            target="_blank"
            style={{
              textDecoration: 'none',
            }}
          >
            <SeeMoreBtn size="md" color="primary" style={{ margin: '0 auto' }}>
              Xem chi tiết{' '}
              <img
                loading="lazy"
                src="/images/chevronRightWhite.svg"
                style={{ height: 18, width: 18, marginLeft: 8 }}
                alt="arrow right"
              />
            </SeeMoreBtn>
          </a>
        </div>
      </CountdownContainer>
    </LunarBannerContainer>
  )
}

export const HomepageLunarBanner = memo(_HomepageLunarBanner)
