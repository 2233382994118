import { memo, useRef, forwardRef, ForwardedRef, useEffect } from 'react'

import { LOCALE } from '@hhgtech/hhg-components/types'
import useIsInView from 'hooks/useIsInView'
import { useRouterExtend } from 'hooks/useRouterExtend'

import { StyledAdContainer } from './index.styled'

export type AdUnitItem = {
  adUnitPath: string
  size: number[] | number[][]
  divId: string
  pos: string[]
  sizeMapping?: [number[], number[][]][]
  contentId?: string
  customTargetings?: { [key: string]: (string | number) | (string | number)[] }
}
export type Props = {
  className?: string
  isHidden?: boolean
  refreshInterval?: number
  onlyRefreshInview?: boolean
} & AdUnitItem
const SlotsById: { [k: string]: any } = {}

const destroyAdSlot = (divId: string) => {
  if (SlotsById[divId]) {
    window.googletag = window.googletag || { cmd: [] }
    const { googletag } = window
    googletag.cmd.push(function () {
      googletag.destroySlots([SlotsById[divId]])
      delete SlotsById[divId]
    })
  }
}

// const _ForceSlugs = [
//   '/su-phat-trien-cua-tre/suc-khoe-tre-em/',
//   '/su-phat-trien-cua-tre/tre-tap-di-mau-giao/',
//   '/nuoi-day-con-cai/',
//   '/mang-thai/3-thang-cuoi/',
//   '/mang-thai/3-thang-giua/',
//   '/mang-thai/cham-soc-me-bau/',
// ]
const _ForcePos = ['TopBanner', 'MidBanner1', 'MidBanner2', 'masthead']
const _getForceRefreshConfig = (locale: LOCALE, pos: string[]) => {
  // if (typeof window === 'undefined') return false
  if (
    locale === LOCALE.Vietnam &&
    // _ForceSlugs.some((s) => window.location.pathname.startsWith(s)) &&
    _ForcePos.some((p) => pos.includes(p))
  )
    return true
}

const displayAd = ({
  contentId,
  adUnitPath,
  size,
  divId,
  pos,
  sizeMapping,
  customTargetings,
  onSuccess,
}: AdUnitItem & {
  onSuccess?: () => void
}) => {
  destroyAdSlot(divId)
  window.googletag = window.googletag || { cmd: [] }

  const { googletag } = window
  let newSlot: any
  googletag.cmd.push(function () {
    const $content_id_container = document.querySelector<HTMLMetaElement>(
      'meta[name="content_id"]',
    )
    const $content_id = contentId || $content_id_container?.content
    newSlot = googletag
      .defineSlot(adUnitPath, size, divId)
      ?.setTargeting('Pos', pos)
      .addService(googletag.pubads())
    if (newSlot) {
      SlotsById[divId] = newSlot
      if ($content_id) newSlot.setTargeting('Content_ID', $content_id)
      if (sizeMapping) newSlot.defineSizeMapping(sizeMapping)
      try {
        if (customTargetings) {
          Object.keys(customTargetings).forEach((k) => {
            if (k) newSlot?.setTargeting(k, customTargetings[k])
          })
        }
      } catch (e) {
        console.error(e)
      }
      googletag.display(divId)
      onSuccess?.()
    }
  })
  return newSlot
}

const DEFAULT_30S_AD_POS = [
  // 'TopBanner',
  // 'MidBanner1',
  // 'MidBanner2',
  // 'EndBanner',
  'Sticky',
]

const ForwaredAdBlock = forwardRef(function InfinityAdBlock(
  {
    className,
    adUnitPath,
    size,
    divId,
    pos,
    sizeMapping,
    refreshInterval, // seconds
    contentId,
    isHidden,
    customTargetings,
    onlyRefreshInview: onlyRefreshInviewProp,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { locale } = useRouterExtend()
  const defaultRefreshInterval =
    refreshInterval ||
    (locale === LOCALE.Vietnam
      ? pos.some((p) => DEFAULT_30S_AD_POS.includes(p))
        ? 30
        : undefined
      : undefined)
  const adRefreshIntervalRef = useRef<number | null>(null)
  const refreshIntervalRef = useRef<number | undefined>(defaultRefreshInterval)
  refreshIntervalRef.current = _getForceRefreshConfig(locale, pos)
    ? 15
    : defaultRefreshInterval

  const onlyRefreshInview =
    _getForceRefreshConfig(locale, pos) || onlyRefreshInviewProp

  useEffect(() => {
    return () => {
      destroyAdSlot(divId)
      if (adRefreshIntervalRef.current)
        clearInterval(adRefreshIntervalRef.current)
    }
  }, [])

  const containerRef = useRef<HTMLDivElement | null>(null)
  const isInView = useIsInView(containerRef)
  const setupRefreshAd = (seconds: number) => {
    if (adRefreshIntervalRef.current !== null) {
      clearInterval(adRefreshIntervalRef.current)
    }
    adRefreshIntervalRef.current = window.setInterval(() => {
      window.googletag = window.googletag || { cmd: [] }
      const { googletag } = window
      googletag.cmd.push(function () {
        if (SlotsById[divId]) {
          googletag.pubads().refresh([SlotsById[divId]])
        }
      })
    }, seconds * 1000)
  }

  useEffect(() => {
    if (isInView && onlyRefreshInview && refreshIntervalRef.current) {
      setupRefreshAd(refreshIntervalRef.current)
    } else if (!isInView && onlyRefreshInview) {
      if (adRefreshIntervalRef.current !== null) {
        clearInterval(adRefreshIntervalRef.current)
      }
    }
  }, [isInView, onlyRefreshInview])
  useEffect(() => {
    if (!isHidden) {
      displayAd({
        contentId,
        adUnitPath,
        size,
        divId,
        pos,
        sizeMapping,
        customTargetings,
        onSuccess: () =>
          refreshIntervalRef.current &&
          !onlyRefreshInview &&
          setupRefreshAd(refreshIntervalRef.current),
      })
    }
  }, [isHidden])

  return (
    <StyledAdContainer ref={containerRef} className={className}>
      <div ref={ref} id={divId} />
    </StyledAdContainer>
  )
})

export const AdBlock = memo(ForwaredAdBlock, (prevProps, nextProps) => {
  return (
    prevProps.adUnitPath === nextProps.adUnitPath &&
    prevProps.divId === nextProps.divId
  )
})
