import { useState, useRef, useEffect } from 'react'

import { formatArticle, formatMedicalReviewedData } from 'api/dataTransform'
import { PATHS } from 'api/paths'
import { callApi, getApiPath, handleGoogleOauthRedirect } from 'api/utils'
import { Container } from 'components/atoms/container'
import { Tabs } from 'components/molecules/tabs'
import { WeekOfPregnancy } from 'components/molecules/weekOfPregnancy'
import { HomePageCommunity } from 'components/organisms/homepageCommunity'
import { RecommendedTopics } from 'components/organisms/homepageRecommendedTopicsCard'
import { RecommendSpotlight } from 'components/organisms/homepageRecommendSpotlight'
import { TopicsDropdown } from 'components/organisms/homepageTopicsDropdown'
import { HomepageTrendingCard } from 'components/organisms/homepageTrendingCard'
import { TrustCard } from 'components/organisms/homepageTrustCard'
import {
  StyledHomeWrapper,
  StyledHomeTrendingContainer,
} from 'components/pages/homepage/index.styled'
import { domainLocales } from 'domainLocales'
import { useGetIsMobile } from 'hooks/useGetIsMobile'
import { useScreenSize } from 'hooks/useScreenSize'
import { isBeforeLunar2023 } from 'interfaces/constants'
import {
  Article,
  ArticleApi,
  Category,
  DropdownTopic,
  ExpertHeading,
  ExpertUserSummaryApi,
  HealthTool,
  MedicalReview,
  MedicalReviewApi,
  SpotlightSummary,
  WeekOfPregnancyApi,
} from 'interfaces/types'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { lazyLoadInstance } from 'utils/lazyLoad'
import { genPropTrackV2, TRACK_GA } from 'utils/tracking'

import { HomepageLunarBanner } from './homepageLunarBanner'
type FeaturedTopicApi = {
  posts: ArticleApi[]
  category: {
    term_id: number
    name: string
    permalink: string
    text_color: string
  }
}

type Props = {
  isTopBarAppears: boolean
  isMobileSsr: boolean
  trendingArticles: ArticleApi[]
  categories: Category[]
  healthTools: HealthTool[]
  healthyLivingArticles: FeaturedTopicApi
  pregnancyArticles: FeaturedTopicApi
  drugArticles: FeaturedTopicApi
  expertsArticles: ExpertUserSummaryApi[]
  medicalReviewed: MedicalReviewApi[]
  spotlights: SpotlightSummary[]
  expertHeading: ExpertHeading
  homepageTopics: DropdownTopic[]
  pregnancyWeeks: WeekOfPregnancyApi[]
}

const PageBody = ({
  isMobileSsr,
  trendingArticles,
  categories,
  isTopBarAppears,
  medicalReviewed: _medicalReviewed,
  spotlights,
  homepageTopics,
  pregnancyWeeks,
}: Props) => {
  const { isMobile: isMobileCsr } = useScreenSize()
  const isMobile = useGetIsMobile(isMobileSsr, isMobileCsr)
  const { formatMessage: f } = useIntl()
  const mounted = useRef(false)
  const router = useRouter()
  const { locale } = router
  const sL = locale || 'vi-VN' // standardized locale

  const [tabIndex, setTabIndex] = useState(0)

  const tabSwipingPosition = useRef([0, 0])

  const [isTrendingLoading, setTrendingLoading] = useState(false)

  const [trendingPosts, setTrendingPosts] = useState<Article[]>(
    trendingArticles
      ? trendingArticles.map((post: ArticleApi) => formatArticle(post))
      : [],
  )

  const medicalReviewed =
    _medicalReviewed && _medicalReviewed.length > 0
      ? formatMedicalReviewedData(_medicalReviewed[0])
      : ({} as MedicalReview)

  const onTabChange = (tabNumber: number) => {
    setTabIndex(tabNumber)
  }

  const onSwipeLeft = () => {
    setTabIndex(1)
  }

  const onSwipeRight = () => {
    setTabIndex(0)
  }

  const onArticleClick = (l: string) => {
    router.push(l)
  }

  const onTrendingTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    tabSwipingPosition.current[0] = e.targetTouches[0].clientX
    tabSwipingPosition.current[1] = e.targetTouches[0].clientX
  }

  const onTrendingTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    tabSwipingPosition.current[1] = e.targetTouches[0].clientX
  }

  const onTrendingTouchEnd = () => {
    if (tabSwipingPosition.current[0] - tabSwipingPosition.current[1] > 100) {
      onSwipeLeft()
    }

    if (tabSwipingPosition.current[0] - tabSwipingPosition.current[1] < -100) {
      onSwipeRight()
    }
  }

  // get trending
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    // your update codes goes here
    setTrendingLoading(true)
    ;(async () => {
      const trendingResults = await callApi(
        tabIndex === 0
          ? getApiPath(PATHS.GET_HOMEPAGE_POSTS, {
              type: '',
            })
          : getApiPath(PATHS.GET_HOMEPAGE_LATEST_POSTS),
        'GET',
      )

      trendingResults &&
        trendingResults.status === 1 &&
        trendingResults.data &&
        setTrendingPosts(
          tabIndex === 0
            ? trendingResults.data.map((post: ArticleApi) =>
                formatArticle(post),
              )
            : trendingResults.data.posts.map((post: ArticleApi) =>
                formatArticle(post),
              ),
        )

      setTrendingLoading(false)
    })()
  }, [tabIndex])

  useEffect(() => {
    if (lazyLoadInstance) {
      lazyLoadInstance.update()
    }
    handleGoogleOauthRedirect()
  }, [])

  return (
    <>
      <StyledHomeWrapper>
        {isBeforeLunar2023() && <HomepageLunarBanner />}
        <StyledHomeTrendingContainer
          key="trending-cards"
          data-is-top-bar-appears={isTopBarAppears}
          className="sticky-container"
        >
          <div className="trending-tab">
            <Container>
              <Tabs
                initialTab={tabIndex}
                tabContent={[
                  {
                    name: f({
                      id: 'home.trending.trending',
                      defaultMessage: 'Trending',
                    }),
                    ...genPropTrackV2(
                      TRACK_GA.TREND,
                      `https://${domainLocales[sL]}/trending`,
                    ),
                  },
                  {
                    name: f({
                      id: 'home.trending.latest',
                      defaultMessage: 'Latest Topics',
                    }),
                    ...genPropTrackV2(
                      TRACK_GA.LATEST_NEWS,
                      `https://${domainLocales[sL]}/latest`,
                    ),
                  },
                ]}
                onChange={onTabChange}
              />
            </Container>
          </div>
          <HomepageTrendingCard
            isMobile={isMobile}
            trendingPosts={trendingPosts}
            spotlights={spotlights}
            onTrendingTouchStart={onTrendingTouchStart}
            onTrendingTouchEnd={onTrendingTouchEnd}
            onTrendingTouchMove={onTrendingTouchMove}
            isTrendingLoading={isTrendingLoading}
            onArticleClick={onArticleClick}
          />
        </StyledHomeTrendingContainer>

        {!isMobile && (
          <RecommendSpotlight spotlights={spotlights} isMobile={false} />
        )}

        <WeekOfPregnancy
          className="homepage-weekofpregnancy"
          data={pregnancyWeeks}
          onClickReadButton={(w) => {
            w.recommended_article?.permalink &&
              router.push(w.recommended_article?.permalink)
          }}
          isMobile={isMobile}
        />

        <HomePageCommunity topics={homepageTopics} isMobile={isMobile} />

        <RecommendedTopics
          isMobile={isMobile}
          categoryLoading={false}
          categories={categories}
          onCategoryClick={onArticleClick}
        />

        {isMobile && <TopicsDropdown topics={homepageTopics} />}

        <TrustCard isMobile={isMobile} medicalReviewed={medicalReviewed} />
      </StyledHomeWrapper>
    </>
  )
}

export { PageBody }
